import { type AuthState, type OktaAuth } from "@okta/okta-auth-js";
import { withOktaAuth } from "@okta/okta-react";
import { type LDMultiKindContext } from "launchdarkly-js-client-sdk";
import { LDProvider, withLDConsumer } from "launchdarkly-react-client-sdk";
import React, { Suspense } from "react";
import { connect } from "react-redux";

import {
  attemptStartProductAnalyticsLogging as attemptStartProductAnalyticsLoggingAction,
  loginUser as loginUserAction,
  updateSessionAfterLogin,
} from "actions";
import { fetchActionIntegrationsAction } from "actions/actionIntegrations";
import { fetchAppIntegrationsAction } from "actions/appIntegrations";
import { fetchHandoffIntegrationsAction } from "actions/handoffIntegrations";
import { fetchOne } from "actions/resources";
import { type Dispatch } from "actions/types";
import { Alerts } from "components/Common/Alerts";
import { Loading } from "components/Common/Loading";
import { Modal } from "components/Common/Modal";
import { SessionTimeoutModal } from "components/Common/ModalSessionTimeout";
import WindowAlert from "components/Common/WindowAlert";
import ErrorPage from "components/Declarative/Pages/ErrorPage";
import { TestVariablesModal } from "components/Shared/Pages/TestVariablesModal/TestVariablesModal";
import { type ErrorState } from "reducers/error/types";
import { type User } from "resourceModels";
import { getResource, getResourcesRequest } from "selectors/resources";
import { adaEmbed, getTestBotStartOptions } from "services/ada-embed";
import { type Client, selectClient } from "services/client";
import { FlagsUpdater } from "services/flags";
import { type RequestState } from "services/requestState";
import { type AuthStateAugmented } from "types/global";

import "components/containers/RootContainer/root.scss";

const AuthenticatedContainer = React.lazy(() =>
  import("components/containers/AuthenticatedContainer").then((module) => ({
    default: module.AuthenticatedContainer,
  })),
);
const UnauthenticatedContainer = React.lazy(() =>
  import("components/containers/UnauthenticatedContainer").then((module) => ({
    default: module.UnauthenticatedContainer,
  })),
);

interface PropsFromState {
  sessionIsAuthenticating: boolean;
  sessionIsAuthenticated: boolean;
  error: ErrorState;
  client: Client | null;
  user?: User | null;
  userRequest?: RequestState | null;
}

interface PropsFromDispatch {
  attemptStartProductAnalyticsLogging: () => void;
  fetchActionIntegrationsIfNecessary: () => void;
  fetchHandoffIntegrationsIfNecessary: () => void;
  loginUser: (username: string, password: string, isSSO: boolean) => void;
  fetchAppIntegrationsIfNecessary: () => void;
  fetchUser: () => Promise<void>;
  updateSession: () => void;
  dispatch: Dispatch;
}

interface PropsFromOkta {
  authState: AuthState | AuthStateAugmented | null;
  oktaAuth: OktaAuth;
}

type Props = PropsFromState & PropsFromDispatch & PropsFromOkta;

interface RootContainerState {
  launchDarklyContext?: LDMultiKindContext;
  oktaEmail?: string;
  isFetchingOktaEmail?: boolean;
}

class RootContainer extends React.Component<Props, RootContainerState> {
  static defaultProps: Partial<Props> = {
    user: null,
    userRequest: null,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      launchDarklyContext: undefined,
      oktaEmail: undefined,
      isFetchingOktaEmail: undefined,
    };
  }

  async componentDidMount() {
    const { user, fetchUser, updateSession, client, dispatch } = this.props;

    const currentPath = String(window.location.pathname);
    const onPasswordSetPage = currentPath.includes("/users/password/set/");

    // If a 403 (forbidden) is returned, this is handled in the middleware, user will be sent to "/"
    if (!user && !onPasswordSetPage) {
      fetchUser()
        .then(() => {
          updateSession();
        })
        .catch((response) => {
          if (
            response.status === 401 ||
            response.status === 403 ||
            response.status === 419
          ) {
            // Do nothing since we expect 401s/403s here, specifically when the user is not logged in
            // 419s also occur if the user directly goes to the login page after the session times out
          } else {
            throw response;
          }
        });
    }

    if (client) {
      await adaEmbed.start(getTestBotStartOptions(dispatch));
    }
  }

  componentDidUpdate() {
    const {
      client,
      user,
      attemptStartProductAnalyticsLogging,
      authState,
      oktaAuth,
      loginUser,
      sessionIsAuthenticated,
      fetchActionIntegrationsIfNecessary,
      fetchAppIntegrationsIfNecessary,
      fetchHandoffIntegrationsIfNecessary,
    } = this.props;

    const { oktaEmail, isFetchingOktaEmail } = this.state;

    const { launchDarklyContext } = this.state;

    // Logged out LD context
    if (client && !user && !launchDarklyContext) {
      this.setState({
        launchDarklyContext: {
          kind: "multi",
          client_handle: {
            key: client.handle,
          },
          bot_status: {
            key: client.botStatus ?? "other",
          },
          hostname: {
            key: window.location.hostname,
          },
        },
      });
    }

    // Logged in LD context
    if (client && user && !launchDarklyContext?.user) {
      this.setState({
        launchDarklyContext: {
          kind: "multi",
          client_handle: {
            key: client.handle,
          },
          user: {
            key: user.email,
          },
          bot_status: {
            key: client.botStatus ?? "other",
          },
          hostname: {
            key: window.location.hostname,
          },
        },
      });
    }

    if (client && user) {
      attemptStartProductAnalyticsLogging();
    }

    if (
      authState &&
      authState.isAuthenticated &&
      !oktaEmail &&
      !isFetchingOktaEmail
    ) {
      // need this guard to prevent sending multiple login requests
      this.setState({
        isFetchingOktaEmail: true,
      });

      oktaAuth.getUser().then((userInfo) => {
        this.setState({
          oktaEmail: userInfo.email,
          isFetchingOktaEmail: false,
        });
        const username = userInfo.email ?? "";
        const password = authState.accessToken?.accessToken ?? "";
        const isSSO = true;
        loginUser(username, password, isSSO);
      });
    }

    if (sessionIsAuthenticated) {
      fetchActionIntegrationsIfNecessary();
      fetchAppIntegrationsIfNecessary();
      fetchHandoffIntegrationsIfNecessary();
    }
  }

  render() {
    const {
      error,
      user,
      userRequest,
      sessionIsAuthenticated,
      sessionIsAuthenticating,
    } = this.props;

    const { launchDarklyContext } = this.state;

    const isUserLoading = !user && userRequest?.isPending;
    const isLoadingState =
      isUserLoading || (sessionIsAuthenticating && !sessionIsAuthenticated);

    if (isLoadingState) {
      return <Loading />;
    }

    let view;

    if (error.pageError) {
      view = <ErrorPage />;
    } else if (!sessionIsAuthenticated) {
      view = <UnauthenticatedContainer />;
    } else {
      view = <AuthenticatedContainer />;
    }

    return (
      <LDProvider
        clientSideID={window.__env.LD_CLIENT_SIDE_ID}
        deferInitialization
        context={launchDarklyContext}
        reactOptions={{
          useCamelCaseFlagKeys: false,
        }}
      >
        <div className="Root">
          <Suspense fallback={<Loading />}>
            {view}
            <Alerts />
            <WindowAlert />
            <Modal />
            {sessionIsAuthenticated && <TestVariablesModal />}
            <SessionTimeoutModal />
            <FlagsUpdater />
          </Suspense>
        </div>
      </LDProvider>
    );
  }
}

const Connector = connect(
  (state): PropsFromState => ({
    client: selectClient(state),
    user: getResource(state, "user"),
    userRequest: getResourcesRequest(state, "user", "default"),
    sessionIsAuthenticating: state.session.isAuthenticating,
    sessionIsAuthenticated: state.session.isAuthenticated,
    error: state.error,
  }),
  (dispatch: Dispatch): PropsFromDispatch => ({
    dispatch,
    fetchActionIntegrationsIfNecessary: () =>
      dispatch(fetchActionIntegrationsAction()),
    fetchAppIntegrationsIfNecessary: () =>
      dispatch(fetchAppIntegrationsAction()),
    fetchHandoffIntegrationsIfNecessary: () =>
      dispatch(fetchHandoffIntegrationsAction()),
    fetchUser: () => dispatch(fetchOne("user")),
    loginUser: (username: string, password: string, isSSO: boolean) => {
      dispatch(loginUserAction(username, password, isSSO));
    },
    updateSession: () => dispatch(updateSessionAfterLogin()),
    attemptStartProductAnalyticsLogging: () =>
      dispatch(attemptStartProductAnalyticsLoggingAction()),
  }),
)(RootContainer);

export default withLDConsumer()(withOktaAuth(Connector));
