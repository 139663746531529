import { useGetArticlesLocalesQuery } from "slices/knowledge/knowledgeApiSlice";

export const useKnowledge = () => {
  const { data: getLocalesResponse } = useGetArticlesLocalesQuery();

  const locales = getLocalesResponse?.data;

  return {
    locales,
  };
};
