import { primitives } from "@adasupport/byron";
import React from "react";

import * as S from "./styles";

export const KnowledgeVideoModal = () => (
  <S.Container>
    <S.Video src="https://fast.wistia.net/embed/iframe/dav2irb2t3?seo=true&videoFoam=true" />
  </S.Container>
);
KnowledgeVideoModal.isLarge = true;
KnowledgeVideoModal.isVideo = true;
KnowledgeVideoModal.closeColor = primitives.palette.white;

export const ActionVideoModal = () => (
  <S.Container>
    <S.Video src="https://fast.wistia.net/embed/iframe/rupkr7jcwi?seo=true&videoFoam=true" />
  </S.Container>
);
ActionVideoModal.isLarge = true;
ActionVideoModal.isVideo = true;
ActionVideoModal.closeColor = primitives.palette.white;

export const ProcessVideoModal = () => (
  <S.Container>
    <S.Video src="https://fast.wistia.net/embed/iframe/c48cvkjgvb?seo=true&videoFoam=true" />
  </S.Container>
);
ProcessVideoModal.isLarge = true;
ProcessVideoModal.isVideo = true;
ProcessVideoModal.closeColor = primitives.palette.white;
