import {
  type AnyAction,
  type Middleware,
  type MiddlewareAPI,
} from "@reduxjs/toolkit";

import { DEFAULT_MESSAGING_LANGUAGE } from "constants/language";
import { type ResponseMessageDto } from "reducers/responses/types";
import { UserAnalyticsService } from "services/userAnalytics";

// TODO: We should type the event properties somewhere before they get out of hand
enum UserAnalyticsEvent {
  USER_CREATED = "USER_CREATED",
  RESPONSE_GREETING_UPDATED = "RESPONSE_GREETING_UPDATED",
  RESPONSE_HANDOFF_CREATED = "RESPONSE_HANDOFF_CREATED",
  RESPONSE_HANDOFF_UPDATED = "RESPONSE_HANDOFF_UPDATED",
  CHANNEL_EMAIL_CREATED = "CHANNEL_EMAIL_CREATED",
  EMAIL_LAUNCH_CONTROL_UPDATED = "EMAIL_LAUNCH_CONTROL_UPDATED",
  GUIDANCE_CREATED = "GUIDANCE_CREATED",
  GENERATIVE_ACTION_CREATED = "GENERATIVE_ACTION_CREATED",
  PROCESS_CREATED = "PROCESS_CREATED",
  PROCESS_CONTENT_UPDATED = "PROCESS_CONTENT_UPDATED",
  AGENT_PERSONA_UPDATED = "AGENT_PERSONA_UPDATED",
  KNOWLEDGE_SOURCE_CREATED = "KNOWLEDGE_SOURCE_CREATED",
  ADA_ARTICLE_CREATED = "ADA_ARTICLE_CREATED",
  USER_ONBOARDED = "USER_ONBOARDED",
}

const handleUserCreatedEvent = (action: AnyAction) => {
  if (action.type !== "CREATE_ONE_USER_SUCCESS") {
    return;
  }

  const { user } = action.data;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { email, name, role, time_zone } = user;

  UserAnalyticsService.capture(UserAnalyticsEvent.USER_CREATED, {
    email,
    name,
    role,
    time_zone,
  });
};

const handleResponseHandoffSavedEvent = (action: AnyAction) => {
  if (action.type !== "SAVE_BOT_CONTENT_SUCCESS") {
    return;
  }

  /*
   * While it looks like we always receive this type, we can't confirm it 100%. Instead of creating arbitrary types
   * to try to appease the TS linter, we will use this and monitor sentry for errors. Each event handler is wrapped
   * in a try/catch regardless to ensure no one failed call blocks the rest of them or the entire middleware chain
   * */
  const handoff = Object.values(action.responses as ResponseMessageDto[]).find(
    (response) => response._type === "handoff",
  );

  if (!handoff) {
    return;
  }

  const { handle, description, created, updated } = handoff;
  const eventData = {
    name: handle,
    description,
  };
  const isUpdate = created !== updated;
  const event = isUpdate
    ? UserAnalyticsEvent.RESPONSE_HANDOFF_UPDATED
    : UserAnalyticsEvent.RESPONSE_HANDOFF_CREATED;

  UserAnalyticsService.capture(event, eventData);
};

const handleResponseGreetingUpdatedEvent = (action: AnyAction) => {
  if (action.type !== "SAVE_BOT_CONTENT_SUCCESS") {
    return;
  }

  /*
   * While it looks like we always receive this type, we can't confirm it 100%. Instead of creating arbitrary types
   * to try to appease the TS linter, we will use this and monitor sentry for errors. Each event handler is wrapped
   * in a try/catch regardless to ensure no one failed call blocks the rest of them or the entire middleware chain
   * */
  const greeting = Object.values(action.responses as ResponseMessageDto[]).find(
    (response) => response._type === "greeting",
  );

  if (!greeting) {
    return;
  }

  const { _id: responseId, handle, description } = greeting;
  UserAnalyticsService.capture(UserAnalyticsEvent.RESPONSE_GREETING_UPDATED, {
    responseId,
    name: handle,
    description,
  });
};

const handleChannelEmailCreatedEvent = (action: AnyAction) => {
  if (action.type !== "api/executeMutation/fulfilled") {
    return;
  }

  const endpointName = action.meta?.arg?.endpointName;

  if (endpointName !== "createEmailPlatform") {
    return;
  }

  UserAnalyticsService.capture(UserAnalyticsEvent.CHANNEL_EMAIL_CREATED);
};

const handleEmailLaunchControlUpdatedEvent = (action: AnyAction) => {
  if (action.type !== "api/executeMutation/fulfilled") {
    return;
  }

  const endpointName = action.meta?.arg?.endpointName;

  if (endpointName !== "updateLaunchControls") {
    return;
  }

  UserAnalyticsService.capture(
    UserAnalyticsEvent.EMAIL_LAUNCH_CONTROL_UPDATED,
    {
      percentage: action.meta.arg.originalArgs.percentage,
      passthrough_address: action.meta.arg.originalArgs.passthrough_address,
    },
  );
};

const handleGuidanceCreatedEvent = (action: AnyAction) => {
  if (action.type !== "guidance/executeMutation/fulfilled") {
    return;
  }

  const endpointName = action.meta?.arg?.endpointName;

  if (endpointName !== "createGuidance") {
    return;
  }

  if (action.meta?.requestStatus !== "fulfilled") {
    return;
  }

  UserAnalyticsService.capture(UserAnalyticsEvent.GUIDANCE_CREATED);
};

const handleActionCreatedEvent = (action: AnyAction) => {
  if (action.type !== "webActions/executeMutation/fulfilled") {
    return;
  }

  const endpointName = action.meta?.arg?.endpointName;

  if (endpointName !== "createWebAction") {
    return;
  }

  if (action.meta?.requestStatus !== "fulfilled") {
    return;
  }

  const { name, description } = action.meta.arg.originalArgs;

  UserAnalyticsService.capture(UserAnalyticsEvent.GENERATIVE_ACTION_CREATED, {
    name,
    description,
  });
};

const handleProcessCreatedEvent = (action: AnyAction) => {
  if (action.type !== "api/executeMutation/fulfilled") {
    return;
  }

  const endpointName = action.meta?.arg?.endpointName;

  if (endpointName !== "createProcess") {
    return;
  }

  UserAnalyticsService.capture(UserAnalyticsEvent.PROCESS_CREATED);
};

const handleProcessContentUpdatedEvent = (action: AnyAction) => {
  if (action.type !== "SAVE_BOT_CONTENT_SUCCESS") {
    return;
  }

  const process = Object.values(action.responses as ResponseMessageDto[]).find(
    (response) => response.is_process,
  );

  if (!process) {
    return;
  }

  // `messages` is a map of language to messages, representing the process "blocks" in the process editor
  const blockCount = process.messages[DEFAULT_MESSAGING_LANGUAGE]?.length;

  if (!blockCount) {
    return;
  }

  UserAnalyticsService.capture(UserAnalyticsEvent.PROCESS_CONTENT_UPDATED, {
    block_count: blockCount,
    active: process.live,
    live_voice: process.live_voice,
  });
};

const handlePersonaUpdatedEvent = (action: AnyAction, store: MiddlewareAPI) => {
  if (action.type !== "SAVE_CLIENT_SUCCESS") {
    return;
  }

  const { changedAttributes } = store.getState().client;

  // TODO: Should we count changing the name as a persona update?
  if (
    !changedAttributes?.botPersona &&
    !changedAttributes?.uiSettings?.chat?.bot_name &&
    !changedAttributes?.avatar?.image
  ) {
    return;
  }

  UserAnalyticsService.capture(UserAnalyticsEvent.AGENT_PERSONA_UPDATED);
};

const handleKnowledgeSourceCreatedEvent = (
  action: AnyAction,
  store: MiddlewareAPI,
) => {
  if (action.type !== "api/executeMutation/fulfilled") {
    return;
  }

  const endpointName = action.meta?.arg?.endpointName;

  if (
    endpointName !== "createWebScraper" &&
    endpointName !== "connectLegacyIntegration"
  ) {
    return;
  }

  let eventProperties = null;

  if (endpointName === "createWebScraper") {
    eventProperties = {
      type: "web",
    };
  }

  if (endpointName === "connectLegacyIntegration") {
    const integration = action.meta?.arg?.originalArgs?.integration;
    eventProperties = {
      type: "integration",
      integration,
    };
  }

  UserAnalyticsService.capture(
    UserAnalyticsEvent.KNOWLEDGE_SOURCE_CREATED,
    eventProperties,
  );

  if (
    endpointName === "createWebScraper" &&
    store.getState().router.location.pathname === "/onboarding/teach"
  ) {
    UserAnalyticsService.capture(UserAnalyticsEvent.USER_ONBOARDED, {
      onboarding_flow_type: "custom",
      content_type: "web",
    });
  }
};

const handleKnowledgeArticleCreatedEvent = (
  action: AnyAction,
  store: MiddlewareAPI,
) => {
  if (action.type !== "api/executeMutation/fulfilled") {
    return;
  }

  const endpointName = action.meta?.arg?.endpointName;

  if (endpointName !== "createAdaArticle") {
    return;
  }

  // Purposefully double destructuring to get the response object - example data: [[{ stuffWeWant }], [id]]
  const [[response]] = action.payload.data;

  if (!response?.success && !response?.created) {
    return;
  }

  UserAnalyticsService.capture(UserAnalyticsEvent.ADA_ARTICLE_CREATED);

  if (store.getState().router.location.pathname === "/onboarding/teach") {
    UserAnalyticsService.capture(UserAnalyticsEvent.USER_ONBOARDED, {
      onboarding_flow_type: "custom",
      content_type: "article",
    });
  }
};

const handleUserOnboardedEvent = (action: AnyAction) => {
  if (action.type !== "api/executeMutation/fulfilled") {
    return;
  }

  const endpointName = action.meta?.arg?.endpointName;

  if (endpointName !== "createOnboardingSampleContent") {
    return;
  }

  const vertical = action.meta?.arg?.originalArgs?.vertical;

  UserAnalyticsService.capture(UserAnalyticsEvent.USER_ONBOARDED, {
    onboarding_flow_type: "sample_content",
    content_type: vertical,
  });
};

export const captureAnalyticsEvent: Middleware =
  (store) => (next) => (action) => {
    const eventHandlers = [
      handleUserCreatedEvent,
      handleResponseGreetingUpdatedEvent,
      handleResponseHandoffSavedEvent,
      handleChannelEmailCreatedEvent,
      handleEmailLaunchControlUpdatedEvent,
      handleGuidanceCreatedEvent,
      handleActionCreatedEvent,
      handleProcessCreatedEvent,
      handleProcessContentUpdatedEvent,
      handlePersonaUpdatedEvent,
      handleKnowledgeSourceCreatedEvent,
      handleKnowledgeArticleCreatedEvent,
      handleUserOnboardedEvent,
    ];

    // Separate try-catch for each event handler to prevent one failing from stopping the rest
    eventHandlers.forEach((handler) => {
      try {
        // `store` will be silently ignored by handlers that don't need it
        handler(action, store);
      } catch (e) {
        console.error("Analytics event capture failed", e, action?.type);
      }
    });

    return next(action);
  };
