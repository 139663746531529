export const WebActionCharacterLimits = {
  name: 128,
  description: 512,
  inputName: 64,
  inputDescription: 512,
  url: 2048,
  headerName: 128,
  headerValue: 2048,
  requestBody: 4096,
  outputKey: 256,
  outputName: 64,
} as const;
