export const DEFAULT_MESSAGING_LANGUAGE = "en";

export const SUPPORTED_LANGUAGES_GENERATIVE_KNOWLEDGE_INGESTION = [
  "en",
  "fr",
  "es",
  "nl",
  "de",
  "it",
  "pt",
  "zh",
  "ar",
];

// Note there is no overlap between SUPPORTED_LANGUAGES_GENERATIVE_KNOWLEDGE_INGESTION and EXPANDED_SUPPORTED_LANGUAGES_GENERATIVE_KNOWLEDGE_INGESTION
// It is expected that the list of languages displayed will be either SUPPORTED_LANGUAGES_GENERATIVE_KNOWLEDGE_INGESTION
// or SUPPORTED_LANGUAGES_GENERATIVE_KNOWLEDGE_INGESTION + EXPANDED_SUPPORTED_LANGUAGES_GENERATIVE_KNOWLEDGE_INGESTION
export const EXPANDED_SUPPORTED_LANGUAGES_GENERATIVE_KNOWLEDGE_INGESTION = [
  "bg",
  "my",
  "zh-tw",
  "hr",
  "cs",
  "da",
  "et",
  "fi",
  "el",
  "ht",
  "hi",
  "hu",
  "id",
  "ja",
  "km",
  "ko",
  "lv",
  "lt",
  "ms",
  "no",
  "pl",
  "pa",
  "ro",
  "ru",
  "sk",
  "sl",
  "sv",
  "tl",
  "ta",
  "th",
  "tr",
  "vi",
  "is",
  "be",
  "uk",
  "he",
  "ca",
  "sq",
  "bs",
  "sr",
  "kk",
  "af",
  "mk",
  "rw",
  "sw",
  "xh",
  "zu",
];
