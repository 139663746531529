import { Button, Icon, TextInput } from "@adasupport/byron";
import FileDownload from "js-file-download";
import React from "react";

import { Banner } from "components/Common/Banner";
import { type ZendeskSupportPlatformAttributes } from "reducers/platforms/models/ZendeskSupportPlatformRecord";
import { type Platform } from "reducers/platforms/types";
import { adaAPI } from "services/api";
import { NO_OP_FUNCTION } from "services/helpers";

import { InputRow } from "../LiveAgents/SalesforceLAModal/InputRow";

export const INVALID_CREDENTIALS_ERROR_MESSAGE =
  "We couldn’t connect to your account. Verify the highlighted fields and try again.";
export const SUNSHINE_APP_NOT_CONNECTED_TO_ZENDESK =
  "Your Zendesk instance is not linked to a Sunshine Conversations App. Please contact your Zendesk representative to resolve issue.";
export const GENERIC_ERROR_MESSAGE = "Something went wrong. Please try again.";

const errorTypes = {
  GENERIC: "generic",
  INVALID_CREDENTIALS_ERROR: "validation_exception",
  SUNSHINE_APP_NOT_CONNECTED_TO_ZENDESK: "integration_validation_exception",
};

interface Props {
  platform: Platform;
  saveClientPlatform: (updatedPlatform: Platform) => void;
  updateZendeskSupportPlatform: (
    updatedAttributes: Partial<ZendeskSupportPlatformAttributes>,
  ) => void;
  isSaveDisabled: boolean;
  closeModal: () => void;
  errorInfo?: {
    type: string;
    platform?: string;
    message?: string;
  };
}

const WrappedCopyField = (props: {
  value: string;
  placeholder: string;
  onChange: (value: string) => void;
  invalid: boolean;
}) => {
  // InputRow component expects an inputComponent prop that can take these 4 props; the wrapper is to
  // translate some prop names / add additional props
  const { value, placeholder, onChange, invalid } = props;

  return (
    <TextInput
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      isInvalid={invalid}
      isReadOnly
    />
  );
};

export const SettingsScreen = ({
  platform,
  saveClientPlatform,
  updateZendeskSupportPlatform,
  isSaveDisabled,
  closeModal,
  errorInfo = undefined,
}: Props) => {
  let errorBanner;
  let invalidZendeskCredentials;

  if (errorInfo) {
    let message;

    if (errorInfo.type === errorTypes.INVALID_CREDENTIALS_ERROR) {
      invalidZendeskCredentials = errorInfo.platform === "zendesk";
      message = INVALID_CREDENTIALS_ERROR_MESSAGE;
    } else if (
      errorInfo.type === errorTypes.SUNSHINE_APP_NOT_CONNECTED_TO_ZENDESK
    ) {
      message = SUNSHINE_APP_NOT_CONNECTED_TO_ZENDESK;
    } else {
      message = GENERIC_ERROR_MESSAGE;
    }

    errorBanner = (
      <Banner icon="Error" intent="negative">
        {message}
      </Banner>
    );
  }

  const downloadApp = async () => {
    const response = await adaAPI.request<Blob>({
      url: "/zendesk_support/zendesk_app",
      method: "GET",
      responseType: "blob",
    });
    const filename =
      response.headers["content-disposition"].split("filename=")[1];
    FileDownload(response.data, filename);
  };

  // TODO: Add link back when we have Zendesk Support specific help docs
  // const guideLink = "https://docs.ada.cx/ada-glass-zendesk-messaging";

  const adaToken = platform.getIn(["record", "auth", "adaToken"]);

  return (
    <>
      <div className="Modal__content ZendeskSupport__Settings">
        {/* <Banner icon="QuestionCircleFilled">
          Need help? Check out our{" "}
          <a href={guideLink} target="_blank" rel="noopener noreferrer">
            Zendesk Support Guide.
          </a>
        </Banner> */}
        {errorBanner}
        <section className="Modal__section">
          <div className="ZendeskSupport__Settings__zd-social__row">
            <InputRow
              // N.B.: "split" rows get an extra class name:
              className="ZendeskSupport__Settings__cell ph-no-capture"
              label="Zendesk Subdomain"
              tooltip={`Your subdomain is the first part of your helpdesk URL: 
              https://[yoursubdomain].zendesk.com`}
              value={platform.getIn(["record", "auth", "subdomain"])}
              placeholder="e.g Mycompany"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateZendeskSupportPlatform({
                  auth: { subdomain: e.target.value },
                })
              }
              invalid={invalidZendeskCredentials}
            />
            <InputRow
              // N.B.: "split" rows get an extra class name:
              className="ZendeskSupport__Settings__cell ph-no-capture"
              label="Zendesk Login Email"
              tooltip="The email you use to login to Zendesk"
              value={platform.getIn(["record", "auth", "email"])}
              placeholder="e.g you@yourcompany.com"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateZendeskSupportPlatform({
                  auth: { email: e.target.value },
                })
              }
              invalid={invalidZendeskCredentials}
            />
          </div>
          <InputRow
            // N.B.: "split" rows get an extra class name:
            className="ZendeskSupport__Settings__cell ph-no-capture"
            label="Zendesk API Token"
            tooltip={`You can get one of these from Zendesk in
          Settings > Channels > API > Token Access`}
            value={platform.getIn(["record", "auth", "apiToken"])}
            placeholder="e.g 1Jc9wXd9nyqAi187LWdJfnJjDY5NMr4ht4Y3nRTn"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateZendeskSupportPlatform({
                auth: { apiToken: e.target.value },
              })
            }
            invalid={invalidZendeskCredentials}
          />
          {adaToken && (
            <div className="ZendeskSupport__Settings__zd-social__row">
              <InputRow
                className="ZendeskSupport__Settings__cell ph-no-capture"
                label="Ada Configuration Token"
                tooltip="Used to authenticate your Zendesk App with Ada."
                value={platform.getIn(["record", "auth", "adaToken"])}
                onChange={NO_OP_FUNCTION}
                inputComponent={WrappedCopyField}
              />
              <Button
                onClick={downloadApp}
                text="Download Zendesk App"
                variant="secondary"
                icon={Icon.Download}
              />
            </div>
          )}
        </section>
      </div>
      <div className="Modal__bottom ZendeskSupport__Settings__bottom">
        <Button onClick={closeModal} text="Cancel" variant="secondary" />
        <Button
          onClick={() => {
            const enabledPlatform = platform.setIn(["record", "enabled"], true);
            saveClientPlatform(enabledPlatform);
          }}
          text="Save"
          isDisabled={isSaveDisabled}
          isLoading={platform.get("isLoading")}
          icon={Icon.Cloud}
        />
      </div>
    </>
  );
};
