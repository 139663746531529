import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { patchFeature } from "actions/resources";
import { ReadOnlyCheckbox as Checkbox } from "components/Common/Checkbox";
import { InputSearch } from "components/Common/InputSearch";
import { getResource } from "selectors/resources";
import {
  type ClientFeatureName,
  type ClientFeatures,
  useClient,
} from "services/client";
import { escapeRegexp } from "services/strings";

import * as S from "./style";

const FEATURE_ALIASES = {
  translations: ["multilingual, languages"],
} as const;

export const DevToolbarClientFeatures = () => {
  const dispatch = useDispatch();
  const { client, updateClient } = useClient();

  const [featureSearchValue, setFeatureSearchValue] = useState("");

  const user = useSelector((state) => getResource(state, "user"));

  const handleFeatureToggle = (
    featureName: ClientFeatureName,
    value: boolean,
  ) => {
    dispatch(
      // update the Client feature in the db
      patchFeature({
        features: { ...client.features, [featureName]: value },
        admin_secret: process.env.ADMIN_SECRET,
        user_email: user?.email,
      }),
    );

    updateClient({
      features: { ...client.features, [featureName]: value },
    });
  };

  const doesFeatureMatchSearch = (featureName: string) => {
    const searchRegExp = new RegExp(escapeRegexp(featureSearchValue), "i");

    if (searchRegExp.test(featureName)) {
      return true;
    }

    if (featureName in FEATURE_ALIASES) {
      const fn = featureName as keyof typeof FEATURE_ALIASES;

      return FEATURE_ALIASES[fn].some((alias) =>
        searchRegExp.test(alias.toLowerCase()),
      );
    }

    return false;
  };

  return (
    <S.Root>
      <S.Title>Client features</S.Title>

      <S.InputContainer>
        <InputSearch
          value={featureSearchValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFeatureSearchValue(e.target.value)
          }
        />
      </S.InputContainer>

      {(Object.keys(client.features) as (keyof ClientFeatures)[])
        .filter((featureName) => doesFeatureMatchSearch(featureName))
        .map((featureName) => (
          <S.CheckboxContainer key={featureName}>
            <Checkbox
              labelSide="right"
              size="small"
              label={featureName}
              handleToggle={(value: boolean) =>
                handleFeatureToggle(featureName, value)
              }
              checked={client.features[featureName] ?? false}
              theme="dark"
            />
          </S.CheckboxContainer>
        ))}
    </S.Root>
  );
};
