import styled from "styled-components";

import { c, v } from "constants/css";

export const SettingsSchedulesModalInputError = styled.div`
  margin-top: 2px;
  line-height: 12px;
  font-size: ${v.xxSmallFontSize};
  color: ${c.colorUiWarn};
  font-weight: ${v.mediumFontWeight};
`;

export const SettingsSchedulesModalTitle = styled.h5`
  padding: 0 36px 0 16px;
  margin: 14px 0 0;
  display: block;
  text-align: center;
`;

export const SettingsSchedulesModalContent = styled.div`
  background: ${c.colorWhite};
  font-size: ${v.smallFontSize};
  max-height: 60vh;
  overflow-y: auto;
  box-sizing: border-box;
`;

export const SettingsSchedulesModalContentExceptions = styled.div`
  background: ${c.colorWhite};
  font-size: ${v.smallFontSize};
  max-height: 60vh;
  overflow: visible;
  box-sizing: border-box;
`;

export const SettingsSchedulesModalBottom = styled.div`
  width: 100%;
  border-radius: 0 0 8px 8px;
  border-top: 1px solid #e8e8eb;
  padding: 16px;
  display: flex;
  background-color: #ffffff;
  justify-content: space-between;
`;

export const SettingsSchedulesModalTimezoneName = styled.div`
  display: flex;
  padding: ${v.gUnitDouble};
`;

export const SettingsSchedulesModalNameInput = styled.div`
  flex: 1;
  padding-right: ${v.gUnitDouble};
  font-size: ${v.smallFontSize};
`;

export const SettingsSchedulesModalNameInputLabel = styled.h2`
  flex: 1;
  padding-right: ${v.gUnitDouble};
  font-size: ${v.smallFontSize};
  margin-bottom: ${v.gUnit};
  color: ${c.colorBlack};
`;

export const SettingsSchedulesModalTimezoneSection = styled.div`
  margin-bottom: ${v.gUnitDouble};
  padding: ${v.gUnitDouble};
  display: flex;
`;

export const SettingsSchedulesModalTimeZoneSelector = styled.div`
  width: 50%;
`;

export const SettingsSchedulesModalButtonCancel = styled.button`
  position: absolute;
  left: ${v.gUnitDouble};
`;

export const SettingsSchedulesModalRepeatSection = styled.div`
  border-top: ${v.gUnit} ${c.colorGrey1} solid;
  padding: ${v.gUnitDouble};
`;

export const SettingsSchedulesModalExceptionSection = styled.div`
  border-top: ${v.gUnit} ${c.colorGrey1} solid;
  padding: ${v.gUnitDouble};
`;

export const SettingsSchedulesModalPanel = styled.div`
  background: ${c.colorWhite};
  box-sizing: border-box;
  font-size: ${v.smallFontSize};
`;

export const SettingsSchedulesModalPanelHeader = styled.h6`
  padding: 0 0 ${v.gUnitHalf} 0;
  display: flex;
  align-items: center;
  line-height: 24px;
`;

export const SettingsSchedulesModalPanelTitle = styled.h6`
  font-weight: ${v.mediumFontWeight};
  color: ${c.colorBlack};
  margin: 0px ${v.gUnitHalf};
`;

export const SettingsSchedulesModalPanelTitleGreyed = styled.span`
  font-weight: ${v.mediumFontWeight};
  color: ${c.colorGrey3Active};
`;

export const SettingsSchedulesModalPanelFooter = styled.div`
  margin-bottom: ${v.gUnitQuadruple};
`;

export const SettingsSchedulesModalPanelFooterButton = styled.button`
  width: 100%;
  border: none;
  border-radius: ${v.smallBorderRadius};
  cursor: pointer;
  background-color: ${c.colorWhite};
  outline: inherit;
  display: flex;
  color: ${c.colorGrey4};
  padding: ${v.gUnit} ${v.gUnitHalf};
  margin: ${v.gUnitHalf} 0;

  &:hover {
    background-color: ${c.colorGrey1};
  }
`;

export const SettingsSchedulesModalPanelIcon = styled.div`
  margin-right: ${v.gUnitHalf};
  fill: ${c.colorBlack};
`;

export const SettingsSchedulesModalPanelHeaderButton = styled.div`
  margin-left: auto;
  height: 24px;
`;

export const SettingsSchedulesModalPanelDivider = styled.hr`
  width: 100%;
  background: ${c.colorGrey2};
  height: 1px;
  border-width: 0px;
  margin: 0;
`;

export const SettingsSchedulesModalErrors = styled.div`
  color: ${c.colorUiWarn};
  margin: 0px 20px;
  display: flex;
  align-items: center;
`;

export const SettingsSchedulesModalErrorsDescription = styled.div`
  color: ${c.colorUiWarn};
  display: flex;
  align-items: center;
  font-size: ${v.xSmallFontSize};
`;

export const SettingsSchedulesMessageAndSave = styled.div`
  display: flex;
`;

export const SettingsSchedulesModalInvalid = styled.p`
  border: 2px solid;
  border-radius: 5px;
  border-color: ${c.colorUiWarn};
`;

export const SettingsSchedulesBottomModal = styled.div`
  padding: ${v.gUnitDouble} !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SettingsSchedulesModalIconTextWrapper = styled.div`
  gap: ${v.gUnitHalf};
  display: flex;
`;
