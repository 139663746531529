// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import moment from "moment";

import { keys } from "services/helpers";
/**
 * The values here should match the values in the tz database
 * (https://en.wikipedia.org/wiki/Tz_database)
 * which is used by pytz, the Python library we use in API.
 *
 * !! NOTE IF YOU ARE ADDING NEW TIMEZONES HERE !!
 * We should prefer location-based timezones (e.g. America/Toronto) over explicit timezones
 * (e.g. EST) to avoid problems with Daylight Saving Time.
 */

/*
  List of canonical timezones that are all DST independant, so Moment should automatically
  set if the time is DST or not. Sorted from UTC-11 to UTC-14 with two timezones
  (picked arbitrarily) per UTC offset, one that changes with DST and one that doesn't
*/

export const TIME_ZONES_CANONICAL = {
  "Pacific/Pago_Pago": {
    abbreviation: ["SST"],
    utcOffset: ["-11:00"],
    hasDST: false,
    name: ["Samoa Standard Time"],
  },
  "Pacific/Honolulu": {
    abbreviation: ["HST"],
    utcOffset: ["-10:00"],
    hasDST: false,
    name: ["Hawaii-Aleutian Standard Time"],
  },
  "America/Adak": {
    abbreviation: ["HST", "HDT"],
    utcOffset: ["-10:00", "-09:00"],
    hasDST: true,
    name: ["Hawaii-Aleutian Standard Time", "Hawaii-Aleutian Daylight Time"],
  },
  "Pacific/Marquesas": {
    abbreviation: ["MART"],
    utcOffset: ["-09:30"],
    hasDST: false,
    name: ["Marquesas Time"],
  },
  "Pacific/Gambier": {
    abbreviation: ["GAMT"],
    utcOffset: ["-09:00"],
    hasDST: false,
    name: ["Gambier Time"],
  },
  "America/Anchorage": {
    abbreviation: ["AKST", "AKDT"],
    utcOffset: ["-09:00", "-08:00"],
    hasDST: true,
    name: ["Alaska Standard Time", "Alaska Daylight Time"],
  },
  "Pacific/Pitcairn": {
    abbreviation: ["PST"],
    utcOffset: ["-08:00"],
    hasDST: false,
    name: ["Pitcairn Standard Time"],
  },
  "America/Los_Angeles": {
    abbreviation: ["PST", "PDT"],
    utcOffset: ["-08:00", "-07:00"],
    hasDST: true,
    name: ["Pacific Standard Time", "Pacific Daylight Time"],
  },
  "America/Phoenix": {
    abbreviation: ["MST"],
    utcOffset: ["-07:00"],
    hasDST: false,
    name: ["Mountain Standard Time (Arizona)"],
  },
  "America/Edmonton": {
    abbreviation: ["MST", "MDT"],
    utcOffset: ["-07:00", "-06:00"],
    hasDST: true,
    name: ["Mountain Standard Time", "Mountain Daylight Time"],
  },
  "America/Costa_Rica": {
    abbreviation: ["CST"],
    utcOffset: ["-06:00"],
    hasDST: false,
    name: ["Central Standard Time (Central America)"],
  },
  "America/Chicago": {
    abbreviation: ["CST", "CDT"],
    utcOffset: ["-06:00", "-05:00"],
    hasDST: true,
    name: ["Central Standard Time", "Central Daylight Time"],
  },
  "America/Cancun": {
    abbreviation: ["EST"],
    utcOffset: ["-05:00"],
    hasDST: false,
    name: ["Eastern Standard Time (Cancun)"],
  },
  "America/Toronto": {
    abbreviation: ["EST", "EDT"],
    utcOffset: ["-05:00", "-04:00"],
    hasDST: true,
    name: ["Eastern Standard Time", "Eastern Daylight Time"],
  },
  "America/Puerto_Rico": {
    abbreviation: ["AMT"],
    utcOffset: ["-04:00"],
    hasDST: false,
    name: ["Atlantic Standard Time"],
  },
  "America/Halifax": {
    abbreviation: ["AT", "AT"],
    utcOffset: ["-04:00", "-03:00"],
    hasDST: true,
    name: ["Atlantic Standard Time", "Atlantic Daylight Time"],
  },
  "America/St_Johns": {
    abbreviation: ["NST", "NDT"],
    utcOffset: ["-03:30", "-02:30"],
    hasDST: true,
    name: ["Newfoundland Standard Time", "Newfoundland Daylight Time"],
  },
  "America/Sao_Paulo": {
    abbreviation: ["ART"],
    utcOffset: ["-03:00"],
    hasDST: false,
    name: ["Brasília Time"],
  },
  "America/Nuuk": {
    abbreviation: ["WGT", "WGST"],
    utcOffset: ["-03:00", "-02:00"],
    hasDST: true,
    name: ["West Greenland Standard Time", "West Greenland Summer Time"],
  },
  "America/Noronha": {
    abbreviation: ["FNT"],
    utcOffset: ["-02:00"],
    hasDST: false,
    name: ["Fernando de Noronha Time"],
  },
  "Atlantic/Cape_Verde": {
    abbreviation: ["CVT"],
    utcOffset: ["-01:00"],
    hasDST: false,
    name: ["Cape Verde Time"],
  },
  "America/Scoresbysund": {
    abbreviation: ["EGT", "EGST"],
    utcOffset: ["-01:00", "+00:00"],
    hasDST: true,
    name: ["East Greenland Time", "East Greenland Summer Time"],
  },
  UTC: {
    abbreviation: ["UTC"],
    utcOffset: ["+00:00"],
    hasDST: false,
    name: ["Coordinated Universal Time"],
  },
  "Europe/London": {
    abbreviation: ["GMT", "BST"],
    utcOffset: ["+00:00", "+01:00"],
    hasDST: true,
    name: ["Greenwich Mean Time", "British Summer Time"],
  },
  "Africa/Lagos": {
    abbreviation: ["WAT"],
    utcOffset: ["+01:00"],
    hasDST: false,
    name: ["West Africa Time"],
  },
  "Europe/Amsterdam": {
    abbreviation: ["CET", "CEST"],
    utcOffset: ["+01:00", "+02:00"],
    hasDST: true,
    name: ["Central European Time", "Central European Summer Time"],
  },
  "Africa/Maputo": {
    abbreviation: ["CAT"],
    utcOffset: ["+02:00"],
    hasDST: false,
    name: ["Central Africa Time"],
  },
  "Europe/Kiev": {
    abbreviation: ["EET", "EEST"],
    utcOffset: ["+02:00", "+03:00"],
    hasDST: true,
    name: ["Eastern European Time", "Eastern European Summer Time"],
  },
  "Africa/Nairobi": {
    abbreviation: ["EAT"],
    utcOffset: ["+03:00"],
    hasDST: false,
    name: ["East Africa Time"],
  },
  "Europe/Istanbul": {
    abbreviation: ["TRT"],
    utcOffset: ["+03:00"],
    hasDST: false,
    name: ["Turkey Time"],
  },
  "Asia/Tehran": {
    abbreviation: ["IRST", "IRDT"],
    utcOffset: ["+03:30", "+04:30"],
    hasDST: true,
    name: ["Iran Standard Time", "Iran Daylight Time"],
  },
  "Asia/Dubai": {
    abbreviation: ["AZT"],
    utcOffset: ["+04:00"],
    hasDST: false,
    name: ["Gulf Standard Time"],
  },
  "Asia/Kabul": {
    abbreviation: ["AFT"],
    utcOffset: ["+04:30"],
    hasDST: false,
    name: ["Afghanistan Time"],
  },
  "Asia/Almaty": {
    abbreviation: ["ALMT"],
    utcOffset: ["+05:00"],
    hasDST: false,
    name: ["Alma-Ata Time"],
  },
  "Asia/Colombo": {
    abbreviation: ["IST"],
    utcOffset: ["+05:30"],
    hasDST: false,
    name: ["India Standard Time"],
  },
  "Asia/Kathmandu": {
    abbreviation: ["NPT"],
    utcOffset: ["+05:45"],
    hasDST: false,
    name: ["Nepal Time"],
  },
  "Asia/Bishkek": {
    abbreviation: ["KGT"],
    utcOffset: ["+06:00"],
    hasDST: false,
    name: ["Kyrgyzstan Time"],
  },
  "Asia/Yangon": {
    abbreviation: ["MMT"],
    utcOffset: ["+06:30"],
    hasDST: false,
    name: ["Myanmar Time"],
  },
  "Asia/Bangkok": {
    abbreviation: ["ICT"],
    utcOffset: ["+07:00"],
    hasDST: false,
    name: ["Indochina Time"],
  },
  "Asia/Hong_Kong": {
    abbreviation: ["HKT"],
    utcOffset: ["+08:00"],
    hasDST: false,
    name: ["Hong Kong Time"],
  },
  "Australia/Eucla": {
    abbreviation: ["ACWST"],
    utcOffset: ["+08:45"],
    hasDST: false,
    name: ["Australian Central Western Standard Time"],
  },
  "Asia/Tokyo": {
    abbreviation: ["JST"],
    utcOffset: ["+09:00"],
    hasDST: false,
    name: ["Japan Standard Time"],
  },
  "Australia/Darwin": {
    abbreviation: ["ACT"],
    utcOffset: ["+09:30"],
    hasDST: false,
    name: ["Australian Central Time"],
  },
  "Australia/Adelaide": {
    abbreviation: ["ACST", "ACDT"],
    utcOffset: ["+09:30", "+10:30"],
    hasDST: true,
    name: [
      "Australian Central Standard Time",
      "Australian Central Daylight Time",
    ],
  },
  "Australia/Brisbane": {
    abbreviation: ["AET"],
    utcOffset: ["+10:00"],
    hasDST: false,
    name: ["Australian Eastern Time"],
  },
  "Australia/Melbourne": {
    abbreviation: ["AEST", "AEDT"],
    utcOffset: ["+10:00", "+11:00"],
    hasDST: true,
    name: [
      "Australian Eastern Standard Time",
      "Australian Eastern Daylight Time",
    ],
  },
  "Australia/Lord_Howe": {
    abbreviation: ["LHST", "LHDT"],
    utcOffset: ["+10:30", "+11:00"],
    hasDST: true,
    name: ["Lord Howe Standard Time", "Lord Howe Daylight Time"],
  },
  "Pacific/Pohnpei": {
    abbreviation: ["PONT"],
    utcOffset: ["+11:00"],
    hasDST: false,
    name: ["Ponape Time"],
  },
  "Pacific/Majuro": {
    abbreviation: ["MHT"],
    utcOffset: ["+12:00"],
    hasDST: false,
    name: ["Marshall Islands Time"],
  },
  "Pacific/Auckland": {
    abbreviation: ["NZST", "NZDT"],
    utcOffset: ["+12:00", "+13:00"],
    hasDST: true,
    name: ["New Zealand Standard Time", "New Zealand Daylight Time"],
  },
  "Pacific/Chatham": {
    abbreviation: ["CHAST", "CHADT"],
    utcOffset: ["+12:45", "+13:45"],
    hasDST: true,
    name: ["Chatham Standard Time", "Chatham Daylight Time"],
  },
  "Pacific/Tongatapu": {
    abbreviation: ["TOT"],
    utcOffset: ["+13:00"],
    hasDST: false,
    name: ["Tonga Time"],
  },
  "Pacific/Kiritimati": {
    abbreviation: ["LINT"],
    utcOffset: ["+14:00"],
    hasDST: false,
    name: ["Line Islands Time"],
  },
} as const;

export const TIME_ZONES_CANONICAL_ARR = keys(TIME_ZONES_CANONICAL).map(
  (value) => {
    const tz = TIME_ZONES_CANONICAL[value];
    const isDST = moment().isDST();
    const utcPrefix = tz.utcOffset[tz.hasDST && isDST ? 1 : 0];
    const tzAbbr = tz.abbreviation[tz.hasDST && isDST ? 1 : 0];

    return {
      value,
      label: `[UTC${utcPrefix}] ${value.replace("_", " ")} - ${tzAbbr}`,
    };
  },
);

export const getTimezoneLabel = (
  timezoneValue: keyof typeof TIME_ZONES_CANONICAL,
) => {
  const tz = TIME_ZONES_CANONICAL[timezoneValue];
  const isDST = moment().isDST();

  return `${
    tz.abbreviation[tz.hasDST && isDST ? 1 : 0]
  } - ${timezoneValue.replace("_", " ")}`;
};

export const getTimezoneUTCOffset = (
  timezoneValue: keyof typeof TIME_ZONES_CANONICAL,
) => {
  const tz = TIME_ZONES_CANONICAL[timezoneValue];
  const isDST = moment().isDST();

  return `UTC ${tz.utcOffset[tz.hasDST && isDST ? 1 : 0]}`;
};

export const getTimezoneName = (
  timezoneValue: keyof typeof TIME_ZONES_CANONICAL,
) => {
  const tz = TIME_ZONES_CANONICAL[timezoneValue];
  const isDST = moment().isDST();

  return tz.name[tz.hasDST && isDST ? 1 : 0];
};
