import Immutable from "immutable";

import { AdaDateTime } from "services/AdaDateTime";

import { CSATSatisfactionStyle, type CsatResultAttributes } from "./types";

export * from "./types";

export const CSAT_MESSAGE_SHOWN_TYPE = "csat_shown";
export const CSAT_MESSAGE_TYPE = "csat";

export function getTimeStringFromTimestamp(
  timestamp: number | string,
  timeZone = "UTC",
): string {
  return AdaDateTime.format(
    AdaDateTime.utcToLocal(timestamp),
    "hh:mm A",
    timeZone,
  );
}

export class CsatResult extends Immutable.Record<CsatResultAttributes>({
  style: CSATSatisfactionStyle.NUMERIC_TEN,
  score: 0,
  originalScore: null,
  surveyType: null,
  feedback: [],
  isPositive: false,
  resolved: null,
  comment: "",
}) {}
