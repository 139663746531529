/* eslint-disable @typescript-eslint/naming-convention */
import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "slices";

export interface GuidanceRes {
  id: string;
  title: string;
  text: string;
  is_live: boolean;
  type: "global" | "contextual";
  created: number;
  updated: number;
  created_by: string;
  updated_by: string;
  notes?: string | null;
  rules: string | null;
}

export interface GuidanceValidationRes {
  priority: "low" | "high" | "critical";
  text: string;
  conflicting_id?: string;
}

export const GuidanceApi = createApi({
  reducerPath: "guidance",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Guidance"],
  endpoints: (builder) => ({
    getGuidance: builder.query({
      query: (id: string) => ({
        method: "GET",
        url: `guidance/${id}`,
      }),
      transformResponse: (response: { data: GuidanceRes }) => ({
        ...response.data,
        isLive: response.data.is_live,
        createdBy: response.data.created_by,
        updatedBy: response.data.updated_by,
        created: response.data.created * 1000, // seconds to ms
        updated: response.data.updated * 1000, // seconds to ms
      }),
      providesTags: ["Guidance"],
    }),
    getGuidances: builder.query({
      query: () => ({
        method: "GET",
        url: "guidance",
      }),
      transformResponse: (response: { data: GuidanceRes[] }) =>
        response.data.map(
          ({
            is_live,
            created_by,
            updated_by,
            created,
            updated,
            rules,
            ...rest
          }) => ({
            isLive: is_live,
            createdBy: created_by,
            updatedBy: updated_by,
            created: created * 1000, // seconds to ms
            updated: updated * 1000, // seconds to ms
            rules,
            ...rest,
          }),
        ),
      providesTags: (result, error, id) => [{ type: "Guidance", id }],
    }),
    createGuidance: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "/guidance",
        data: body,
      }),
      invalidatesTags: ["Guidance"],
    }),
    updateGuidance: builder.mutation({
      query: ({
        id,
        ...body
      }: Omit<
        GuidanceRes,
        "created" | "updated" | "created_by" | "updated_by" | "type"
      >) => ({
        method: "PUT",
        url: `/guidance/${id}`,
        data: body,
      }),
      invalidatesTags: ["Guidance"],
    }),
    updateGuidanceNotes: builder.mutation({
      query: ({ id, notes }: { id: string; notes: string }) => ({
        method: "PUT",
        url: `/guidance/${id}/notes`,
        data: { notes },
      }),
    }),
    deleteGuidance: builder.mutation({
      query: (id: string) => ({
        method: "DELETE",
        url: `/guidance/${id}`,
      }),
      invalidatesTags: ["Guidance"],
    }),
    validateGuidance: builder.mutation({
      query: ({
        text,
        title,
        id,
      }: {
        text: string;
        title: string;
        id?: string;
      }) => ({
        method: "POST",
        url: "/guidance/validate",
        data: { text, title, id },
      }),
      transformResponse: (response: { data: GuidanceValidationRes[] }) =>
        response.data,
    }),
  }),
});

export const {
  useGetGuidanceQuery,
  useGetGuidancesQuery,
  useCreateGuidanceMutation,
  useDeleteGuidanceMutation,
  useUpdateGuidanceMutation,
  useUpdateGuidanceNotesMutation,
  useValidateGuidanceMutation,
} = GuidanceApi;
