import { primitives, tokens } from "@adasupport/byron";
import styled, { css } from "styled-components";

import { cssVariables } from "constants/css";

export const Space = styled.div<
  | { height: keyof typeof tokens.spacing }
  | { width: keyof typeof tokens.spacing }
>`
  ${(p) =>
    "height" in p
      ? `height: ${tokens.spacing[p.height]};`
      : css`
          display: inline-block;
          width: ${tokens.spacing[p.width]};
        `}

  flex-shrink: 0;
  flex-grow: 0;
`;

const noFlex = css`
  flex-shrink: 0;
  flex-grow: 0;
`;

/** @deprecated Use Space with height prop */
export const VSpace = styled.div<{ size: keyof typeof primitives.spacing }>`
  height: ${(p) => primitives.spacing[p.size]};
  ${noFlex};
`;

/** @deprecated Use Space with height prop */
export const VSpace1 = styled.div`
  height: ${primitives.spacing.space1};
  ${noFlex};
`;
/** @deprecated Use Space with height prop */
export const VSpace2 = styled.div`
  height: ${primitives.spacing.space2};
  ${noFlex};
`;
/** @deprecated Use Space with height prop */
export const VSpace3 = styled.div`
  height: ${primitives.spacing.space3};
  ${noFlex};
`;
/** @deprecated Use Space with height prop */
export const VSpace4 = styled.div`
  height: ${primitives.spacing.space4};
  ${noFlex};
`;
/** @deprecated Use Space with height prop */
export const VSpace5 = styled.div`
  height: ${primitives.spacing.space5};
  ${noFlex};
`;
/** @deprecated Use Space with height prop */
export const VSpace8 = styled.div`
  height: ${primitives.spacing.space8};
  ${noFlex};
`;
/** @deprecated Use Space with height prop */
export const VSpace9 = styled.div`
  height: ${primitives.spacing.space9};
  ${noFlex};
`;
/** @deprecated Use Space with height prop */
export const VSpace10 = styled.div`
  height: ${primitives.spacing.space10};
  ${noFlex};
`;
/** @deprecated Use Space with height prop */
export const VSpace12 = styled.div`
  height: ${primitives.spacing.space12};
  ${noFlex};
`;

/** @deprecated Use Space with width prop */
export const HSpace = styled.div<{ size: keyof typeof primitives.spacing }>`
  display: inline-block;
  width: ${(p) => primitives.spacing[p.size]};
  ${noFlex};
`;

/** @deprecated Use Space with width prop */
export const HSpace1 = styled.span`
  display: inline-block;
  width: ${primitives.spacing.space1};
  ${noFlex};
`;

/** @deprecated Use Space with width prop */
export const HSpace2 = styled.span`
  display: inline-block;
  width: ${primitives.spacing.space2};
  ${noFlex};
`;
/** @deprecated Use Space with width prop */
export const HSpace4 = styled.span`
  display: inline-block;
  width: ${primitives.spacing.space4};
  ${noFlex};
`;
/** @deprecated Use Space with width prop */
export const HSpace5 = styled.span`
  display: inline-block;
  width: ${primitives.spacing.space5};
  ${noFlex};
`;
/** @deprecated Use Space with width prop */
export const HSpace12 = styled.span`
  display: inline-block;
  width: ${primitives.spacing.space12};
  ${noFlex};
`;

/** @deprecated Use Space with height prop */
export const Space4 = styled.div`
  height: ${cssVariables.space4};
`;

/** @deprecated Use Space with height prop */
export const Space5 = styled.div`
  height: ${cssVariables.space5};
`;

/** @deprecated Use Space with height prop */
export const Space6 = styled.div`
  height: ${cssVariables.space6};
`;

/** @deprecated Use Space with height prop */
export const Space14 = styled.div`
  height: ${primitives.spacing.space14};
`;
