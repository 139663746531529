import {
  type Article,
  type ArticleMetadata,
  type FullArticle,
  type KBSettings,
  type SerializedArticle,
  type SerializedArticleMetadata,
  type SerializedFullArticle,
  type SerializedKBSettings,
} from "./types";

export const deserializeArticle = (
  articleData: SerializedArticle,
): Article => ({
  id: articleData.id,
  externalId: articleData.external_id,
  title: articleData.title,
  url: articleData.url,
  enabled: articleData.enabled,
  updated: articleData.updated,
  views: articleData.views,
  helpfulnessRate: articleData.helpfulness_rate,
  language: articleData.language,
  locale: articleData.locale,
  source: articleData.source,
});

export const deserializeArticles = (
  articleData: SerializedArticle[],
): Article[] => articleData.map(deserializeArticle);

export const deserializeFullArticle = (
  articleData: SerializedFullArticle,
): FullArticle => ({
  id: articleData.id,
  externalId: articleData.external_id,
  title: articleData.title,
  url: articleData.url,
  enabled: articleData.enabled,
  updated: articleData.updated,
  language: articleData.language,
  locale: articleData.locale,
  content: articleData.content,
  source: articleData.source,
});

export const deserializeArticleMetadata = (
  articleMetadata: SerializedArticleMetadata,
): ArticleMetadata => ({
  totalItems: articleMetadata.total_items,
  currentPageNumber: articleMetadata.current_page_number,
  currentLimit: articleMetadata.current_limit,
});

export const deserializeKBSettings = (
  settingsData: SerializedKBSettings,
): KBSettings => ({
  lastSyncStart: settingsData.last_sync_start,
  lastSyncFinish: settingsData.last_sync_finish,
  syncStatus: settingsData.sync_status,
  languages: settingsData.languages,
  integrations: settingsData.integrations,
  generativeReplies: settingsData.generative_replies,
  syncErrorMessage: settingsData.sync_error_message,
});
