import { tokens } from "@adasupport/byron";
import styled, { css } from "styled-components";

import { cssVariables } from "constants/css";

export const VariablePicker = styled.div<{ isDisabled?: boolean }>`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  ${({ isDisabled }) => isDisabled && `cursor: not-allowed;`}
`;

export const Toggle = styled.div<{
  focused?: boolean;
  invalid?: boolean;
  isDisabled?: boolean;
}>`
  ${({ isDisabled }) => isDisabled && `pointer-events: none;`}
  display: flex;
  gap: 8px;
  cursor: pointer;
  white-space: nowrap;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  height: 36px;
  border-radius: 6px;
  border: 1px solid ${tokens.colors.border.formInput};
  padding: 4px 6px;
  background-color: ${tokens.colors.background.main};

  ${(p) =>
    p.focused &&
    css`
      border-color: ${tokens.colors.border.formInputFocused};
      box-shadow: 0 0 0 4px ${tokens.colors.border.formInputFocusedShadow};
    `};

  ${(p) =>
    p.invalid &&
    css`
      border-color: ${tokens.colors.border.formInputError};
    `}
`;

export const ToggleContent = styled.span`
  flex: 1 1 0;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MenuDropdown = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  margin-top: 4px;
  z-index: ${cssVariables.zIndexModal};
  min-width: 240px;
  background-color: ${tokens.colors.background.main};
  box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 0 10px 0 rgb(0 0 0 / 18%);
  border-radius: 8px;
  padding: 16px;
`;

export const VariablesContainer = styled.div`
  max-height: 300px;
  padding: 16px;
  margin: 0 -16px -16px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const SectionTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  color: ${tokens.colors.text.muted};
  margin-bottom: 8px;
`;

export const SectionContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
`;

export const EmptyText = styled.div`
  font-size: 12px;
  color: ${tokens.colors.text.muted};
  margin-top: 12px;
`;

export const Placeholder = styled.span`
  color: ${tokens.colors.text.muted};
  font-style: italic;
  font-weight: normal;
`;
