import { primitives, tokens } from "@adasupport/byron";
import styled from "styled-components";

export const Txt = styled.span<{
  block?: boolean;
  color?: keyof typeof tokens.colors.text | keyof typeof primitives.palette;
  size?: keyof typeof primitives.fontSize;
  weight?: keyof typeof primitives.fontWeight;
  fontFamily?: keyof typeof primitives.fontFamily;
  lineHeight?: keyof typeof primitives.lineHeight;
  noWrap?: boolean;
}>`
  display: ${(p) => (p.block ? "block" : "inline")};
  color: ${(p) =>
    // eslint-disable-next-line no-nested-ternary
    p.color
      ? p.color in tokens.colors.text
        ? tokens.colors.text[p.color as keyof typeof tokens.colors.text]
        : primitives.palette[p.color as keyof typeof primitives.palette]
      : "inherit"};
  font-size: ${(p) => (p.size ? primitives.fontSize[p.size] : "inherit")};
  font-weight: ${(p) =>
    p.weight ? primitives.fontWeight[p.weight] : "inherit"};
  font-family: ${(p) =>
    p.fontFamily ? primitives.fontFamily[p.fontFamily] : "inherit"};
  line-height: ${(p) =>
    p.lineHeight ? primitives.lineHeight[p.lineHeight] : "inherit"};
  white-space: ${(p) => (p.noWrap ? "nowrap" : "inherit")};
`;

// Name "Text" conflicts with external libraries, which prevent IDEs from performing auto-imports
/** @alias Txt */
export const Text = Txt;

export const TruncatedText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
`;
