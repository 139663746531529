import classnames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setState as setStateAction } from "actions";
import { closeModalAction } from "actions/modal";
import { Button } from "components/Common/Button";
import { ModalDelete } from "components/Common/ModalDelete";
import { ModalEditRawJSON } from "components/Common/ModalEditRawJSON";
import { ModalEditTraining } from "components/Common/ModalEditTraining";
import { ModalEnforceMFA } from "components/Common/ModalEnforceMFA";
import { ModalExpiredPassword } from "components/Common/ModalExpiredPassword";
import { ModalProfile } from "components/Common/ModalProfile";
import { ModalResponseDetails } from "components/Common/ModalResponseDetails";
import { ModalWarning } from "components/Common/ModalWarning";
import { useSplitOnProduct } from "components/Common/ProductSplitter";
import { EditBuilderABTestModal } from "components/Declarative/Pages/ABTesting/EditABTestModal";
import CreateBusinessEventModal from "components/Declarative/Pages/BusinessEvents/ModalCreateBusinessEvent";
import { CampaignUploadCsvModal } from "components/Declarative/Pages/Campaigns/ModalCampaignUploadCsv";
import { CreateCampaignModal } from "components/Declarative/Pages/Campaigns/ModalCreateCampaign";
import { ConversationsResolutionsSuccessModal } from "components/Declarative/Pages/Conversations/ConversationsResolutions/ConversationsResolutionsSuccessModal";
import { CreateConversationsTopicModal } from "components/Declarative/Pages/Conversations/ConversationsTopics/ModalCreateConversationsTopic";
import { CollectiveEditsFolderModal } from "components/Declarative/Pages/Folders/Modals/CollectiveEdits";
import { FolderModal } from "components/Declarative/Pages/Folders/Modals/CreateUpdateFolder";
import { DeleteFolderModal } from "components/Declarative/Pages/Folders/Modals/DeleteFolder";
import { CreateGoalModal } from "components/Declarative/Pages/Goals/ModalCreateGoal";
import { DeclareWinnerBuilderABTestModal } from "components/Declarative/Pages/Responses/ResponsesEditor/DeclareWinnerABTestModal";
import { EditVariantWeightModal } from "components/Declarative/Pages/Responses/ResponsesEditor/EditVariantWeightModal";
import { RenameVariantModal } from "components/Declarative/Pages/Responses/ResponsesEditor/RenameVariantModal";
import ModalBulkAddTraining from "components/Declarative/Pages/Responses/ResponsesEditor/ResponseEditorTrainer/ModalBulkAddTraining";
import ModalMoveTraining from "components/Declarative/Pages/Responses/ResponsesEditor/ResponseEditorTrainer/ModalMoveTraining";
import { SetExpressionLanguageModal } from "components/Declarative/Pages/Responses/ResponsesEditor/ResponseEditorTrainer/SetExpressionLanguageModal";
import AuthenticationModal from "components/Declarative/Pages/Settings/SettingsAuthentication/SettingsAuthenticationModal";
import SalesforceLAModal from "components/Declarative/Pages/Settings/SettingsPlatforms/LiveAgents/SalesforceLAModal";
import SettingsPlatformsNuanceLAModal from "components/Declarative/Pages/Settings/SettingsPlatforms/LiveAgents/SettingsPlatformsNuanceLAModal";
import SettingsPlatformOracleModal from "components/Declarative/Pages/Settings/SettingsPlatforms/LiveAgents/SettingsPlatformsOracleModal";
import { ModalZendeskTicketingConfig } from "components/Declarative/Pages/Settings/SettingsPlatforms/ModalZendeskTicketingConfig";
import ChannelSetupIntroModal from "components/Declarative/Pages/Settings/SettingsPlatforms/SelfServeChannelSetup/ChannelSetupIntroModal";
import ChannelSetupSelectionModal from "components/Declarative/Pages/Settings/SettingsPlatforms/SelfServeChannelSetup/ChannelSetupSelectionModal";
import SettingsPlatformsActionsModal from "components/Declarative/Pages/Settings/SettingsPlatforms/SettingsPlatformsActionsModal";
import SettingsPlatformsAdaEmailModal from "components/Declarative/Pages/Settings/SettingsPlatforms/SettingsPlatformsAdaEmailModal";
import { SettingsPlatformsChatModal } from "components/Declarative/Pages/Settings/SettingsPlatforms/SettingsPlatformsChatModal";
import { SettingsPlatformsDataApiModal } from "components/Declarative/Pages/Settings/SettingsPlatforms/SettingsPlatformsDataApiModal";
import { SettingsPlatformsDataComplianceApiModal } from "components/Declarative/Pages/Settings/SettingsPlatforms/SettingsPlatformsDataComplianceApiModal";
import { SettingsPlatformsEndUsersApiModal } from "components/Declarative/Pages/Settings/SettingsPlatforms/SettingsPlatformsEndUsersApiModal";
import SettingsPlatformsHandoffsModal from "components/Declarative/Pages/Settings/SettingsPlatforms/SettingsPlatformsHandoffsModal";
import { connectSettingsPlatformsHandoffsOauthModal as SettingsPlatformsHandoffsOauthModal } from "components/Declarative/Pages/Settings/SettingsPlatforms/SettingsPlatformsHandoffsOauthModal";
import { SettingsPlatformsHttpModal } from "components/Declarative/Pages/Settings/SettingsPlatforms/SettingsPlatformsHttpModal";
import { SettingsPlatformsKnowledgeApiModal } from "components/Declarative/Pages/Settings/SettingsPlatforms/SettingsPlatformsKnowledgeApiModal";
import SettingsPlatformsMessengerModal from "components/Declarative/Pages/Settings/SettingsPlatforms/SettingsPlatformsMessengerModal";
import { SettingsPlatformsProactiveSmsApiModal } from "components/Declarative/Pages/Settings/SettingsPlatforms/SettingsPlatformsProactiveSmsApiModal";
import SettingsPlatformsSmsModal from "components/Declarative/Pages/Settings/SettingsPlatforms/SettingsPlatformsSmsModal";
import SettingsPlatformsZendeskModal from "components/Declarative/Pages/Settings/SettingsPlatforms/SettingsPlatformsZendeskModal";
import { IntegrationSuccessMessage as DeclarativeIntegrationSuccessMessage } from "components/Declarative/Pages/Settings/SettingsPlatforms/SettingsSmartSearch/IntegrationSuccessMessage";
import SunshineConversationsModal from "components/Declarative/Pages/Settings/SettingsPlatforms/SunshineConversationsModal";
import { ZendeskAgentWorkspaceModal } from "components/Declarative/Pages/Settings/SettingsPlatforms/ZendeskAgentWorkspaceModal";
import { ZendeskSupportModal } from "components/Declarative/Pages/Settings/SettingsPlatforms/ZendeskSupportModal";
import SettingsSatisfactionModal from "components/Declarative/Pages/Settings/SettingsSatisfaction/SettingsSatisfactionModal";
import {
  ConfluenceIntegrationModal,
  PinterestIntegrationModal,
  SalesforceIntegrationModal,
  WebsiteImportModal,
} from "components/Generative/Pages/Customization/Integrations";
import { IntegrationSuccessMessage as GenerativeIntegrationSuccessMessage } from "components/Generative/Pages/Customization/Integrations/IntegrationSuccessMessage";
import { ArticleAvailabilityModal } from "components/Generative/Pages/Knowledge/ArticleAvailabilityModal";
import { ConnectGeneratedTopicsModal } from "components/Shared/Pages/Conversations/ConversationsTopics/ModalConnectTopics";
import { HowThisWorksModal } from "components/Shared/Pages/PrelaunchHome/HowThisWorksModal";
import RestoreTrainingErrorModal from "components/Shared/Pages/Responses/ResponsesEditor/ResponseVersions/RestoreTrainingErrorModal";
import RestoreTrainingWarningModal from "components/Shared/Pages/Responses/ResponsesEditor/ResponseVersions/RestoreTrainingWarningModal";
import { RestoreVariableWarningModal } from "components/Shared/Pages/Responses/ResponsesEditor/ResponseVersions/RestoreVariableWarningModal";
import { ModalAddEditDomain } from "components/Shared/Pages/Settings/SettingsDomains/ModalAddEditDomain";
import { SettingsSmartSearchConfluence } from "components/Shared/Pages/Settings/SettingsPlatforms/SettingsSmartSearchConfluenceModal";
import { SettingsSmartSearchPinterest } from "components/Shared/Pages/Settings/SettingsPlatforms/SettingsSmartSearchPinterestModal";
import { SettingsSmartSearchSalesforceModal } from "components/Shared/Pages/Settings/SettingsPlatforms/SettingsSmartSearchSalesforceModal";
import { SettingsSmartSearchZendeskModal } from "components/Shared/Pages/Settings/SettingsPlatforms/SettingsSmartSearchZendeskModal";
import { ConnectionGuide } from "components/Shared/Pages/Settings/SettingsPlatforms/SettingsSmartSearchZendeskModal/ConnectionGuide";
import { SettingsSchedulesModal } from "components/Shared/Pages/Settings/SettingsSchedules/SettingsSchedulesModal";
import { NO_OP_FUNCTION } from "services/helpers";
import colors from "stylesheets/utilities/colors.scss";

import {
  ActionVideoModal,
  KnowledgeVideoModal,
  ProcessVideoModal,
} from "../TrainingPageHeader/demoVideos";
import "./style.scss";

/** @deprecated Use `useModal` hook */
export const Modal = () => {
  const dispatch = useDispatch();
  const setState: typeof setStateAction = (reducer, payload) =>
    dispatch(setStateAction(reducer, payload));
  const [isAnimating, setIsAnimating] = useState(true);
  const modal = useSelector((state) => state.modal);
  const modalOpenState = useSelector((state) => state.modalOpenState);
  const splitOnProduct = useSplitOnProduct();

  const handleClose = useCallback(() => {
    const { onClose } = modal.modalProps;
    dispatch(closeModalAction());

    if (onClose) {
      onClose();
    }
  }, [dispatch, modal]);

  const modalComponents = {
    MODAL_PROFILE: ModalProfile,
    SETTINGS_PLATFORMS_CHAT_MODAL: SettingsPlatformsChatModal,
    SETTINGS_PLATFORMS_MESSENGER_MODAL: SettingsPlatformsMessengerModal,
    SETTINGS_PLATFORMS_SMS_MODAL: SettingsPlatformsSmsModal,
    SETTINGS_PLATFORMS_NUANCE_LIVEAGENT_MODAL: SettingsPlatformsNuanceLAModal,
    SETTINGS_PLATFORMS_ORACLE_MODAL: SettingsPlatformOracleModal,
    SETTINGS_PLATFORMS_SALESFORCE_LIVEAGENT_MODAL: SalesforceLAModal,
    SETTINGS_PLATFORMS_ZENDESK_LIVEAGENT_MODAL: SettingsPlatformsZendeskModal,
    SETTINGS_PLATFORMS_ZAW_LIVEAGENT_MODAL: ZendeskAgentWorkspaceModal,
    SETTINGS_PLATFORMS_ZENDESK_SUPPORT_LIVEAGENT_MODAL: ZendeskSupportModal,
    SETTINGS_PLATFORMS_SUNSHINE_MODAL: SunshineConversationsModal,
    SETTINGS_PLATFORMS_ACTIONS_MODAL: SettingsPlatformsActionsModal,
    SETTINGS_PLATFORMS_HANDOFFS_MODAL: SettingsPlatformsHandoffsModal,
    SETTINGS_PLATFORMS_HANDOFFS_OAUTH_MODAL:
      SettingsPlatformsHandoffsOauthModal,
    SETTINGS_PLATFORMS_ZENDESK_TICKETING_MODAL: ModalZendeskTicketingConfig,
    SETTINGS_PLATFORMS_ADA_EMAIL_MODAL: SettingsPlatformsAdaEmailModal,
    SETTINGS_PLATFORMS_HTTP_MODAL: SettingsPlatformsHttpModal,
    SMARTSEARCH_CONFLUENCE_MODAL: splitOnProduct({
      declarative: SettingsSmartSearchConfluence,
      generative: ConfluenceIntegrationModal,
    }) as () => JSX.Element,
    SMARTSEARCH_PINTEREST_MODAL: splitOnProduct({
      declarative: SettingsSmartSearchPinterest,
      generative: PinterestIntegrationModal,
    }) as () => JSX.Element,
    SMARTSEARCH_SALESFORCE_MODAL: splitOnProduct({
      declarative: SettingsSmartSearchSalesforceModal,
      generative: SalesforceIntegrationModal,
      freeTrial: SalesforceIntegrationModal,
    }) as () => JSX.Element,
    SMARTSEARCH_ZENDESK_MODAL: splitOnProduct({
      declarative: SettingsSmartSearchZendeskModal,
      generative: ConnectionGuide,
      freeTrial: ConnectionGuide,
    }) as () => JSX.Element,
    LEGACY_INTEGRATION_SUCCESS: splitOnProduct({
      declarative: DeclarativeIntegrationSuccessMessage,
      generative: GenerativeIntegrationSuccessMessage,
      freeTrial: GenerativeIntegrationSuccessMessage,
    }) as () => JSX.Element,
    MODAL_WARNING: ModalWarning,
    MODAL_DELETE: ModalDelete,
    SETTINGS_SATISFACTION_MODAL: SettingsSatisfactionModal,
    MODAL_AUTHENTICATION: AuthenticationModal,
    SETTINGS_SCHEDULES_MODAL: SettingsSchedulesModal,
    MODAL_ENFORCE_MFA: ModalEnforceMFA,
    MODAL_PASSWORD_EXPIRE: ModalExpiredPassword,
    MODAL_MOVE_TRAINING: ModalMoveTraining,
    MODAL_BULK_TRAINING: ModalBulkAddTraining,
    MODAL_EDIT_RAW_JSON: ModalEditRawJSON,
    MODAL_EDIT_TRAINING: ModalEditTraining,
    SELF_SERVE_CHANNEL_SELECTION: ChannelSetupSelectionModal,
    SELF_SERVE_CHANNEL_INTRO: ChannelSetupIntroModal,
    MODAL_CREATE_CAMPAIGN: CreateCampaignModal,
    MODAL_CAMPAIGN_UPLOAD_CSV: CampaignUploadCsvModal,
    MODAL_ADD_EDIT_DOMAIN: ModalAddEditDomain,
    MODAL_CREATE_BUSINESS_EVENT: CreateBusinessEventModal,
    MODAL_RESTORE_TRAINING_ERROR: RestoreTrainingErrorModal,
    MODAL_RESTORE_TRAINING_WARNING: RestoreTrainingWarningModal,
    MODAL_RESTORE_VARIABLE_WARNING: RestoreVariableWarningModal,
    MODAL_RESPONSE_DETAILS: ModalResponseDetails,
    MODAL_CREATE_GOAL: CreateGoalModal,
    MODAL_CREATE_CONVERSATIONS_TOPIC: CreateConversationsTopicModal,
    MODAL_CONNECT_GENERATED_TOPICS: ConnectGeneratedTopicsModal,
    SETTINGS_PLATFORMS_DATA_API_MODAL: SettingsPlatformsDataApiModal,
    SETTINGS_PLATFORMS_DATA_COMPLIANCE_API_MODAL:
      SettingsPlatformsDataComplianceApiModal,
    SETTINGS_PLATFORMS_PROACTIVE_SMS_API_MODAL:
      SettingsPlatformsProactiveSmsApiModal,
    MODAL_EDIT_BUILDER_AB_TEST: EditBuilderABTestModal,
    MODAL_DECLARE_WINNER_BUILDER_AB_TEST: DeclareWinnerBuilderABTestModal,
    MODAL_FOLDER: FolderModal,
    MODAL_DELETE_FOLDER: DeleteFolderModal,
    MODAL_COLLECTIVE_EDITS_FOLDER: CollectiveEditsFolderModal,
    MODAL_EDIT_VARIANT_WEIGHT: EditVariantWeightModal,
    MODAL_RENAME_VARIANT: RenameVariantModal,
    MODAL_SET_EXPRESSION_LANGUAGE: SetExpressionLanguageModal,
    MODAL_CONVERSATIONS_RESOLUTIONS_SUCCESS:
      ConversationsResolutionsSuccessModal,
    MODAL_KNOWLEDGE_VIDEO: KnowledgeVideoModal,
    MODAL_ACTION_VIDEO: ActionVideoModal,
    MODAL_PROCESS_VIDEO: ProcessVideoModal,
    MODAL_HOW_THIS_WORKS: HowThisWorksModal,
    SETTINGS_PLATFORMS_KNOWLEDGE_API_MODAL: SettingsPlatformsKnowledgeApiModal,
    SETTINGS_PLATFORMS_END_USERS_API_MODAL: SettingsPlatformsEndUsersApiModal,
    MODAL_ARTICLE_AVAILABILITY: ArticleAvailabilityModal,
    WEBSITE_IMPORT_MODAL: WebsiteImportModal,
  } as const;

  const CurrentModal = modal.view
    ? (modalComponents[modal.view as keyof typeof modalComponents] as React.FC)
    : null;

  // you can use this to set display properties for a modal instead of all these properties set on the component itself
  // this has the added benefit of being able to change them dynamically
  const displayProperties = modal.displayProperties[modal.view] || {};

  const isSmall = Boolean(
    (CurrentModal && "isSmall" in CurrentModal && CurrentModal.isSmall) ||
      displayProperties.size === "small",
  );
  const isLarge = Boolean(
    (CurrentModal && "isLarge" in CurrentModal && CurrentModal.isLarge) ||
      displayProperties.size === "large",
  );

  const isVideoModal = Boolean(
    CurrentModal && "isVideo" in CurrentModal && CurrentModal.isVideo,
  );

  const overflowVisible = Boolean(
    CurrentModal &&
      "overflowVisible" in CurrentModal &&
      CurrentModal.overflowVisible,
  );
  const customCloseColor =
    CurrentModal && "closeColor" in CurrentModal && CurrentModal.closeColor;

  const { isCloseable } = modalOpenState;

  useEffect(() => {
    const close = (e: KeyboardEvent) =>
      e.key === "Escape" && (isCloseable ? handleClose() : NO_OP_FUNCTION);
    window.addEventListener("keydown", (e: KeyboardEvent) => close(e));

    return () => window.removeEventListener("keydown", close);
  }, [handleClose, isCloseable]);

   useEffect(() => {
     if (modal.isOpen) {
       setIsAnimating(true);
       // Prevent background scrolling when modal is open
       document.body.classList.add("Modal__body-open");
     } else {
       // Restore original overflow when modal is closed
       document.body.classList.remove("Modal__body-open");
     }
     
     // Cleanup function to ensure the class is removed if component unmounts while modal is open
     return () => {
       document.body.classList.remove("Modal__body-open");
     };
   }, [modal.isOpen, setIsAnimating]);

  return (
    <div
      id="modal"
      data-testid="modal"
      className={classnames("Modal", {
        "Modal--small": isSmall,
        "Modal--large": isLarge,
        "Modal--active": modal.isOpen,
      })}
    >
      <div
        className="Modal__mask"
        onClick={() => (isCloseable ? handleClose() : NO_OP_FUNCTION)}
        aria-hidden="true"
      />
      <div
        onAnimationEnd={() => setIsAnimating(false)}
        className={classnames("Modal__container", {
          "Modal__container--small": isSmall,
          "Modal__container--large": isLarge,
        })}
      >
        <div
          className={classnames("Modal__inner-container", {
            "Modal__inner-container__overflow-visible": overflowVisible,
          })}
        >
          {isCloseable && (
            <Button
              icon="Close"
              title="Close Modal"
              clear={!isVideoModal}
              onClick={handleClose}
              fillColor={customCloseColor || colors.colorGrey4}
              customClassName={classnames("Modal__container__close", {
                "Modal__container__close--video": isVideoModal,
              })}
            />
          )}
          {!!CurrentModal && (
            <CurrentModal
              {...modal.modalProps}
              setState={setState}
              isAnimating={isAnimating}
            />
          )}
        </div>
      </div>
    </div>
  );
};
