import { Icon, tokens } from "@adasupport/byron";
import React, { useCallback, useEffect, useState } from "react";
import useOnClickOutside from "react-cool-onclickoutside";
import { createPortal } from "react-dom";

import {
  ALL_SCOPES,
  type AUTO_CAPTURE_VARIABLE_SCOPE,
  type GLOBAL_VARIABLE_SCOPE,
  type SENSITIVE_VARIABLE_SCOPE,
  type VariableScope,
} from "constants/variables";
import {
  VARIABLE_TYPE_LABELS,
  type Variable,
  getVariableType,
  getVariableValueType,
  useFilteredVariables,
  useVariables,
} from "services/variables";

import { InputText } from "../InputText";
import { Pill } from "../Primitives";
import { HSpace1, VSpace3 } from "../Space";
import { VariablePalette } from "../VariablePalette";
import { VariablePill } from "../VariablePill";

import * as S from "./styles";

export const VariablePicker = ({
  value,
  onChange,
  variables,
  isInvalid,
  isDisabled,
  allowedScopes = ALL_SCOPES,
  allowedScopesForCreation = allowedScopes as (
    | typeof AUTO_CAPTURE_VARIABLE_SCOPE
    | typeof GLOBAL_VARIABLE_SCOPE
    | typeof SENSITIVE_VARIABLE_SCOPE
  )[],
  clearable = true,
  placeholder = "Choose a variable",
}: {
  value: string | null | undefined;
  onChange: (value: string | null) => void;
  variables?: Variable[];
  isInvalid?: boolean;
  isDisabled?: boolean;
  allowedScopes?: readonly VariableScope[];
  allowedScopesForCreation?: (
    | typeof AUTO_CAPTURE_VARIABLE_SCOPE
    | typeof GLOBAL_VARIABLE_SCOPE
    | typeof SENSITIVE_VARIABLE_SCOPE
  )[];
  clearable?: boolean;
  placeholder?: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [search, setSearch] = useState("");
  const { variables: allVariables } = useVariables();
  let availableVariables = variables || allVariables;
  const rootRef = useOnClickOutside(() => setIsOpen(false));
  const toggleRef = React.createRef<HTMLDivElement>();
  const menuDropdownRef = React.createRef<HTMLDivElement>();
  const searchInputRef = React.createRef<HTMLTextAreaElement>();

  useEffect(() => {
    if (!isOpen) {
      setIsCreateMode(false);
    }
  }, [isOpen]);

  availableVariables = availableVariables?.filter((v) =>
    allowedScopes.includes(v.scope),
  );

  const filteredVariables = useFilteredVariables(availableVariables, search);

  const selectedVariable = availableVariables?.find((v) => v._id === value);

  const highlightedVariable =
    search !== ""
      ? filteredVariables.auto_capture[0] ||
        filteredVariables.global[0] ||
        filteredVariables.meta[0] ||
        filteredVariables.token[0] ||
        filteredVariables.sensitive[0] ||
        filteredVariables.unknown[0]
      : undefined;

  const renderVariablePill = (variable: Variable) => (
    <VariablePill
      key={variable._id}
      name={variable.name}
      type={getVariableType(variable)}
      valueType={getVariableValueType(variable)}
      onClick={() => {
        onChange(variable._id);
        setIsOpen(false);
      }}
      isSelected={variable === highlightedVariable}
    />
  );

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!isOpen) {
        return;
      }

      if (e.key === "Escape") {
        setIsOpen(false);
      }

      if (highlightedVariable && e.key === "Enter") {
        onChange(highlightedVariable._id);
        setIsOpen(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [highlightedVariable, onChange, isOpen]);

  useEffect(() => {
    if (isOpen) {
      setSearch("");
      setTimeout(() => {
        searchInputRef.current?.focus();
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const updateDropdownPosition = useCallback(() => {
    if (!isOpen) {
      return;
    }

    const adjustPosition = () => {
      const dropdown = menuDropdownRef.current;

      if (dropdown && toggleRef.current) {
        const toggleRect = toggleRef.current.getBoundingClientRect();

        dropdown.style.top = `${toggleRect.bottom}px`;
        dropdown.style.left = `${toggleRect.left}px`;
        dropdown.style.width = `${toggleRect.width}px`;

        const viewportHeight = window.innerHeight;

        if (toggleRect.bottom + dropdown.offsetHeight > viewportHeight) {
          dropdown.style.top = `${toggleRect.top - dropdown.offsetHeight}px`;
          dropdown.style.marginTop = "-4px";
        } else {
          dropdown.style.marginTop = "4px";
        }
      }
    };

    adjustPosition();

    // One more time in case page layout has changed
    setTimeout(adjustPosition, 0);
  }, [isOpen, menuDropdownRef, toggleRef]);

  useEffect(() => {
    updateDropdownPosition();
  }, [updateDropdownPosition]);

  // Also update on scroll
  useEffect(() => {
    window.addEventListener("scroll", updateDropdownPosition, true);

    return () => {
      window.removeEventListener("scroll", updateDropdownPosition, true);
    };
  }, [updateDropdownPosition]);

  return (
    <S.VariablePicker ref={rootRef} isDisabled={isDisabled}>
      <S.Toggle
        focused={isOpen}
        invalid={isInvalid}
        onMouseDown={() => setIsOpen(true)}
        ref={toggleRef}
        isDisabled={isDisabled}
      >
        <S.ToggleContent>
          {selectedVariable ? (
            <VariablePill
              name={selectedVariable.name}
              type={getVariableType(selectedVariable)}
              valueType={getVariableValueType(selectedVariable)}
            />
          ) : (
            <S.Placeholder>{placeholder}</S.Placeholder>
          )}
        </S.ToggleContent>

        {!value || !clearable ? (
          <Icon.ChevronDown />
        ) : (
          <span
            style={{ lineHeight: 0 }}
            onMouseDown={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            onClick={() => {
              onChange(null);
              setIsOpen(false);
            }}
            title="Clear selection"
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                onChange(null);
                setIsOpen(false);
              }
            }}
          >
            <Icon.Close
              color={tokens.colors.text.muted}
              width={16}
              height={16}
            />
          </span>
        )}
      </S.Toggle>

      {isOpen &&
        createPortal(
          <S.MenuDropdown
            ref={menuDropdownRef}
            onMouseDown={(e) => e.stopPropagation()}
          >
            {isCreateMode ? (
              <VariablePalette
                allowedScopes={allowedScopesForCreation}
                onCreate={(id) => {
                  onChange(id);
                  setIsOpen(false);
                }}
                defaultAttributes={{
                  name: search,
                }}
              />
            ) : (
              <>
                <InputText
                  search
                  higherRef={searchInputRef}
                  value={search}
                  onChange={setSearch}
                  placeholder="Search by name"
                />

                {allowedScopesForCreation.length > 0 && (
                  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                  <div onMouseDown={(e) => e.preventDefault()}>
                    <VSpace3 />

                    <Pill
                      outline
                      color="muted"
                      onClick={() => setIsCreateMode(true)}
                      style={{ cursor: "pointer" }}
                    >
                      <Icon.Sparkle width={16} height={16} />
                      <HSpace1 />
                      Make a new variable
                    </Pill>
                  </div>
                )}

                {filteredVariables.global.length !== 0 ||
                filteredVariables.meta.length !== 0 ||
                filteredVariables.token.length !== 0 ||
                filteredVariables.sensitive.length !== 0 ||
                filteredVariables.auto_capture.length !== 0 ||
                filteredVariables.unknown.length !== 0 ? (
                  <S.VariablesContainer>
                    {filteredVariables.auto_capture.length !== 0 && (
                      <div>
                        <S.SectionTitle>
                          {VARIABLE_TYPE_LABELS.auto_capture}
                        </S.SectionTitle>
                        <S.SectionContent>
                          {filteredVariables.auto_capture.map(
                            renderVariablePill,
                          )}
                        </S.SectionContent>
                      </div>
                    )}

                    {filteredVariables.global.length !== 0 && (
                      <div>
                        <S.SectionTitle>
                          {VARIABLE_TYPE_LABELS.global}
                        </S.SectionTitle>
                        <S.SectionContent>
                          {filteredVariables.global.map(renderVariablePill)}
                        </S.SectionContent>
                      </div>
                    )}

                    {filteredVariables.meta.length !== 0 && (
                      <div>
                        <S.SectionTitle>
                          {VARIABLE_TYPE_LABELS.meta}
                        </S.SectionTitle>
                        <S.SectionContent>
                          {filteredVariables.meta.map(renderVariablePill)}
                        </S.SectionContent>
                      </div>
                    )}

                    {filteredVariables.token.length !== 0 && (
                      <div>
                        <S.SectionTitle>
                          {VARIABLE_TYPE_LABELS.token}
                        </S.SectionTitle>
                        <S.SectionContent>
                          {filteredVariables.token.map(renderVariablePill)}
                        </S.SectionContent>
                      </div>
                    )}

                    {filteredVariables.sensitive.length !== 0 && (
                      <div>
                        <S.SectionTitle>
                          {VARIABLE_TYPE_LABELS.sensitive}
                        </S.SectionTitle>
                        <S.SectionContent>
                          {filteredVariables.sensitive.map(renderVariablePill)}
                        </S.SectionContent>
                      </div>
                    )}

                    {filteredVariables.unknown.length !== 0 && (
                      <div>
                        <S.SectionTitle>
                          {VARIABLE_TYPE_LABELS.unknown}
                        </S.SectionTitle>
                        <S.SectionContent>
                          {filteredVariables.unknown.map(renderVariablePill)}
                        </S.SectionContent>
                      </div>
                    )}
                  </S.VariablesContainer>
                ) : (
                  <S.EmptyText>No matches</S.EmptyText>
                )}
              </>
            )}
          </S.MenuDropdown>,
          document.body,
        )}
    </S.VariablePicker>
  );
};
