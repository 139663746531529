import { Icon, primitives } from "@adasupport/byron";
import React, { type ReactNode, useState } from "react";

import { NO_OP_FUNCTION } from "services/helpers";

import * as S from "./styles";

function NotificationIcon(notificationType: "error" | "warning" | "info") {
  if (notificationType === "error") {
    return (
      <Icon.Exclamation
        width={24}
        height={24}
        color={primitives.palette.red600}
      />
    );
  }

  if (notificationType === "warning") {
    return (
      <Icon.Exclamation
        width={24}
        height={24}
        color={primitives.palette.orange600}
      />
    );
  }

  return (
    <Icon.Info width={24} height={24} color={primitives.palette.blue600} />
  );
}

/** @deprecated Use Notification from @adasupport/byron instead */
export function Notification({
  children,
  closeable = true,
  className = "",
  description,
  notificationType,
  onClose = NO_OP_FUNCTION,
  closeButtonTestId,
  title,
}: {
  children?: ReactNode;
  closeButtonTestId?: string;
  closeable?: boolean;
  className?: string;
  description?: string;
  notificationType: "error" | "warning" | "info";
  onClose?: () => void;
  title?: string;
}) {
  const [isVisible, setIsVisible] = useState(true);

  const handleOnClose = () => {
    setIsVisible(false);

    onClose();
  };

  if (!isVisible) {
    return null;
  }

  return (
    <S.Notification notificationType={notificationType} className={className}>
      <S.Icon>{NotificationIcon(notificationType)}</S.Icon>
      <S.Content>
        {title && (
          <S.Title notificationType={notificationType}>{title}</S.Title>
        )}
        {Boolean(children || description) && (
          <div>{children || description}</div>
        )}
      </S.Content>
      {closeable && (
        <S.CloseButton data-testid={closeButtonTestId} onClick={handleOnClose}>
          <Icon.CloseSmall color={primitives.palette.slate500} />
        </S.CloseButton>
      )}
    </S.Notification>
  );
}
