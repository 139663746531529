import { primitives } from "@adasupport/byron";
import React from "react";

import * as S from "./styles";

// TODO: Use `useVideoModal` hook instead, e.g.:
//  openVideoModal({
//    videoUrl: "https://fast.wistia.net/embed/iframe/s4c4pirhgn?seo=true&videoFoam=true&fullscreenButton=true",
//  });
export const HowThisWorksModal = () => {
  const videoSlug = "d46ip6p5yf";

  return (
    <S.Container>
      <S.Video
        allowFullScreen
        src={`https://fast.wistia.net/embed/iframe/${videoSlug}?seo=true&videoFoam=true&fullscreenButton=true`}
      />
    </S.Container>
  );
};

HowThisWorksModal.isLarge = true;
HowThisWorksModal.isVideo = true;
HowThisWorksModal.closeColor = primitives.palette.white;
