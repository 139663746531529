import { createApi } from "@reduxjs/toolkit/query/react";

import { type CoachingDetails } from "features/coaching";
import { adaAPI } from "services/api";
import { type GeneratedTopicsV2 } from "slices/generatedTopics/generatedTopicsV2Api";

/** @deprecated This interface is too generic, use GenerativeEvent instead */
export interface ReasoningLog {
  [key: string]: unknown;
  type: "reasoning_log";
  client_id: string;
  conversation_id: string;
  created_at: string;
  direction: string;
  event_type: string;
  message: string;
  step_type?:
    | "reply_to_customer"
    | "search_knowledge"
    | "handoff"
    | "web_action"
    | "process"
    | "no_matching_action"
    | "playbook";
  step_id: string;
  name?: string;
  context?: {
    guidance: {
      id: string;
      text: string;
      title: string;
      rules:
        | {
            id: string;
            rules: string;
          }
        | undefined;
    }[];
    steps: {
      arguments: {
        name: string;
        is_required: boolean;
      }[];
      description: string;
      name: string;
      type: string;
    }[];

    /** @deprecated */
    coaching: {
      id: string;
      coaching: string;
      intent: string;
    }[];
  };
  args?: {
    query: string;
    [key: string]: string;
  };
  outcome?: {
    status: "succeeded" | "failed";
    error_message: string | null;
    return_values: unknown;
  };
  metadata?: {
    llm_calls?: {
      module_id?: string;
      returned_coaching?: ReturnedCoaching[];
    }[];
  };
  _id: string;
  rules: {
    id: string;
    rules: string;
  } | null;
  generated_topic?: GeneratedTopicsV2;
}

export type ReasoningLogResponse = ReasoningLog[];

export type ReturnedCoaching =
  | {
      id: string;
      intent: string;
      instructions: string;
    }
  | {
      id: string;
      intent?: string;
      article_title: string;
      chunk: string;
    };

interface GenerativeEventBase {
  _id: string;
  created_at: string;
  context?: {
    steps: Array<{
      name: string;
      description: string;
    }>;
    guidance?: unknown[];
  };
  metadata?: {
    llm_calls?: Array<{
      module_id:
        | "planner"
        | "contextual_response"
        | "followup_question"
        | "search_knowledge"
        | "action_req_gathering"
        | "handoff";

      used_coaching_ids?: string[];

      // TODO FEED-276: Remove me
      /** @deprecated */
      returned_coaching?: ReturnedCoaching[];

      coaching_details?: CoachingDetails[];
    }>;
  };
}

export type MessageSentEvent = GenerativeEventBase & {
  event_type: "message_sent";
  message: string;
  original_message: string | null;
  direction: "user_to_system" | "system_to_user" | "system_to_assistant";
};

export type ReplyToCustomerEvent = GenerativeEventBase & {
  event_type: "step_invoked";
  step_type: "reply_to_customer";
  args: {
    message: string;
    is_final: boolean;
  };
};

export type SearchKnowledgeEvent = GenerativeEventBase & {
  event_type: "step_invoked";
  step_type: "search_knowledge";
  name: "search_knowledge";
  args: {
    query: string;
  };
  outcome:
    | {
        status: "succeeded";
        return_values: Array<{
          title: string;
          knowledge_id: string;
          source: string;
          rulesets: Array<{
            id: string;
            rules: string;
          }>;
          content: string;
          url?: string;
        }>;
      }
    | {
        status: "failed";
        error_message: string;
        return_values: null;
      };
};

export type ActionRunEvent = GenerativeEventBase & {
  event_type: "step_invoked";
  step_type: "web_action";
  step_id: string;
  name: string;
  outcome:
    | {
        status: "succeeded";
        return_values: unknown;
      }
    | {
        status: "failed";
        error_message: string;
      };
};

export type ProcessRunEvent = GenerativeEventBase & {
  event_type: "step_invoked";
  step_type: "process";
  step_id: string;
  name: string;
};

export type NoMatchingActionevent = GenerativeEventBase & {
  event_type: "step_invoked";
  step_type: "no_matching_action";
};

export type HandoffEvent = GenerativeEventBase & {
  event_type: "step_invoked";
  step_type: "handoff";
  step_id: string;
  name: string;
};

export type PlaybookEvent = GenerativeEventBase & {
  event_type: "step_invoked";
  step_type: "playbook";
  step_id: string;
  name: string;
  args: {
    EXIT?: boolean;
  };
};

export type InstructionReceivedEvent = GenerativeEventBase & {
  event_type: "instruction_received";
};

export type VariableValueSetEvent = GenerativeEventBase & {
  event_type: "variable_value_set";
};

export type GenerativeEvent =
  | MessageSentEvent
  | ReplyToCustomerEvent
  | SearchKnowledgeEvent
  | ProcessRunEvent
  | ActionRunEvent
  | NoMatchingActionevent
  | HandoffEvent
  | InstructionReceivedEvent
  | VariableValueSetEvent;

export const ReasoningLogApi = createApi({
  reducerPath: "reasoningLog",
  baseQuery: (props) => adaAPI.request({ ...props }),
  tagTypes: ["ReasoningLog"],
  endpoints: (builder) => ({
    getReasoningLog: builder.query({
      query: (id: string) => ({
        url: `conversations/generative-events/${id}`,
      }),
      transformResponse: (response: { data: ReasoningLogResponse }) =>
        response.data,
    }),
  }),
});

export const { useGetReasoningLogQuery } = ReasoningLogApi;
