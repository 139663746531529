import { type BaseQueryFn } from "@reduxjs/toolkit/dist/query/react";
import { createApi } from "@reduxjs/toolkit/query/react";
import { type AxiosError, type AxiosRequestConfig, type Method } from "axios";
import qs from "qs";

import { adaAPI } from "services/api";

interface ApiError {
  status: number;
  data: unknown;
}

interface ErrorWithDataMessage {
  data: {
    message: string;
  };
}

export function isErrorWithDataMessage(
  error: unknown,
): error is ErrorWithDataMessage {
  return (
    typeof error === "object" &&
    error !== null &&
    "data" in error &&
    typeof (error as { data: unknown }).data === "object" &&
    (error as { data: { message: unknown } }).data.message !== undefined &&
    typeof (error as { data: { message: unknown } }).data.message === "string"
  );
}

// from RTK docs: https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#axios-basequery
export const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: "" },
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig["method"];
      data?: AxiosRequestConfig["data"];
      params?: AxiosRequestConfig["params"];
    },
    unknown,
    ApiError
  > =>
  async ({ url, method, data, params }, api) => {
    try {
      const result = await adaAPI.request({
        url: baseUrl + url,
        method: method as Method,
        data,
        params,
        paramSerializer: (rawParams: unknown) =>
          qs.stringify(rawParams, { arrayFormat: "repeat" }),
      });

      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;

      if (err.response?.status === 419) {
        // force logout to avoid infinite retries when session expires
        api.dispatch({ type: "UNAUTHENTICATE_SUCCESS" });
      }

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        } as ApiError,
      };
    }
  };

export const AdaApiSlice = createApi({
  reducerPath: "api",
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    "ClientSecretVariables",
    "Coaching",
    "Variable",
    "Process",
    "GeneratedTopics",
    "GenerativeProcess",
    "OnboardingChecklist",
    "Playbooks",
    "ProactiveConversations",
    "GetArticles",
    "ArticlesLocales",
    "GetRulesets",
    "GetSources",
    "GetKBSettings",
    "AnalyticsMetric",
    "AnalyticsUserPreferences",
  ],
  endpoints: () => ({}),
});
