import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { closeModalAction } from "actions/modal";
import { Button } from "components/Common/Button";
import { DebouncedInput } from "components/Common/DebouncedInput";
import { SelectSearchDropdown } from "components/Common/SelectSearchDropdown";
import SvgIcon from "components/Common/SvgIcon";
import {
  handleAddScheduleRowAction,
  handleDeleteScheduleRowAction,
  handleSaveScheduleModalAction,
  handleUpdateScheduleModalAction,
  handleUpdateScheduleRowAction,
} from "components/Shared/Pages/Settings/SettingsSchedules/actions";
import { TIME_ZONES_CANONICAL_ARR } from "services/timezones";
import colors from "stylesheets/utilities/colors.scss";

import { SettingsScheduleRow } from "./SettingsScheduleRow";
import * as S from "./styles";

const BLOCK_NAME = "SettingsSchedulesModal";
const MODAL_ERROR_MESSAGE = "Resolve errors to save this schedule";

export const SettingsSchedulesModal = () => {
  const settingsScheduledBlockSchedules = useSelector(
    (state) => state.settingsScheduledBlockSchedules,
  );
  const schedule = useSelector((state) => state.settingsScheduleModal);
  const modal = useSelector((state) => state.modal);
  const dispatch = useDispatch();

  function scheduleNameHasConflicts() {
    const currentModalIndex = modal.modalProps.scheduleIndex;

    // If it is invalid, return the error message
    return settingsScheduledBlockSchedules.some(
      (existingSchedule, index) =>
        // Don't want to compare against the schedule we're editing

        index !== currentModalIndex && existingSchedule.name === schedule.name,
    );
  }

  /**
   * Count up and return the top 3 most used timezones.
   */
  function getTimezoneDropdownTopOptions() {
    // Create a memo of the number of occurrences of each timezone
    // in the existing schedules
    const topOptions = settingsScheduledBlockSchedules
      .reduce((acc: { timezone: string; count: number }[], currentSchedule) => {
        if (!acc.find((entry) => currentSchedule.timezone === entry.timezone)) {
          return [...acc, { timezone: currentSchedule.timezone, count: 1 }];
        }

        return acc.map((entry) => {
          if (entry.timezone === currentSchedule.timezone) {
            return {
              ...entry,
              count: entry.count + 1,
            };
          }

          return entry;
        });
      }, [])
      .sort((a, b) => b.count - a.count);

    return topOptions
      .slice(0, 3)
      .map(
        (entry) =>
          TIME_ZONES_CANONICAL_ARR.find(
            (timezone) => timezone.value === entry.timezone,
          ) || { value: entry.timezone, label: entry.timezone },
      ); // fallback to the string if it's not in the map
  }

  const handleUpdateRow = useCallback(
    (
      value: Record<string, unknown>,
      scheduleType: "normalOperatingDaysRows" | "specialOperatingDaysRows",
    ) => {
      dispatch(handleUpdateScheduleRowAction({ ...value, scheduleType }));
    },
    [dispatch],
  );

  const handleUpdateNormalOperatingDaysRows = useCallback(
    (value: Record<string, unknown>) => {
      handleUpdateRow(value, "normalOperatingDaysRows");
    },
    [handleUpdateRow],
  );

  const handleUpdateSpecialOperatingDaysRows = useCallback(
    (value: Record<string, unknown>) => {
      handleUpdateRow(value, "specialOperatingDaysRows");
    },
    [handleUpdateRow],
  );

  const handleDeleteRow = useCallback(
    (
      value: Record<string, unknown>,
      scheduleType: "normalOperatingDaysRows" | "specialOperatingDaysRows",
    ) => {
      dispatch(handleDeleteScheduleRowAction({ ...value, scheduleType }));
    },
    [dispatch],
  );

  const handleDeleteNormalOperatingDaysRows = useCallback(
    (value: Record<string, unknown>) => {
      handleDeleteRow(value, "normalOperatingDaysRows");
    },
    [handleDeleteRow],
  );

  const handleDeleteSpecialOperatingDaysRows = useCallback(
    (value: Record<string, unknown>) => {
      handleDeleteRow(value, "specialOperatingDaysRows");
    },
    [handleDeleteRow],
  );

  const isScheduleInvalid = Boolean(
    !schedule.name || scheduleNameHasConflicts() || !schedule.isValid,
  );

  const { normalOperatingDaysRows, specialOperatingDaysRows } = schedule;

  return (
    <div>
      <S.SettingsSchedulesModalTitle>
        {modal.modalProps.newSchedule
          ? "New Recurring Schedule"
          : `Edit ${schedule.name}`}
      </S.SettingsSchedulesModalTitle>
      <S.SettingsSchedulesModalContent>
        <S.SettingsSchedulesModalTimezoneSection>
          <S.SettingsSchedulesModalNameInput>
            <S.SettingsSchedulesModalNameInputLabel>
              Schedule Name
            </S.SettingsSchedulesModalNameInputLabel>
            <DebouncedInput
              placeholder="Type a name for this schedule"
              value={schedule.name}
              onChange={(name: string) =>
                dispatch(handleUpdateScheduleModalAction({ name }))
              }
              isInvalid={scheduleNameHasConflicts()}
            />
            {scheduleNameHasConflicts() && (
              <S.SettingsSchedulesModalInputError>
                This schedule name is already in use
              </S.SettingsSchedulesModalInputError>
            )}
          </S.SettingsSchedulesModalNameInput>
          <S.SettingsSchedulesModalTimeZoneSelector>
            <S.SettingsSchedulesModalNameInputLabel>
              Timezone
            </S.SettingsSchedulesModalNameInputLabel>
            <SelectSearchDropdown
              placeholder="Select time zone"
              placeholderIcon="Globe"
              topOptions={getTimezoneDropdownTopOptions()}
              options={TIME_ZONES_CANONICAL_ARR}
              value={schedule.timezone}
              onChange={(timezone) =>
                dispatch(handleUpdateScheduleModalAction({ timezone }))
              }
            />
          </S.SettingsSchedulesModalTimeZoneSelector>
        </S.SettingsSchedulesModalTimezoneSection>
        <S.SettingsSchedulesModalRepeatSection>
          <S.SettingsSchedulesModalPanel>
            <S.SettingsSchedulesModalPanelHeader>
              <SvgIcon
                customClassName={`${BLOCK_NAME}__panel-icon`}
                icon="BrowserRefresh"
                height={18}
              />
              <S.SettingsSchedulesModalPanelTitle>
                Repeat every
              </S.SettingsSchedulesModalPanelTitle>
            </S.SettingsSchedulesModalPanelHeader>
            <S.SettingsSchedulesModalContent>
              {normalOperatingDaysRows.map((row, index) => (
                <React.Fragment key={`normalOperating${index}`}>
                  <S.SettingsSchedulesModalPanelDivider />
                  <SettingsScheduleRow
                    rowIndex={index}
                    row={row}
                    onChange={handleUpdateNormalOperatingDaysRows}
                    onDelete={handleDeleteNormalOperatingDaysRows}
                  />
                </React.Fragment>
              ))}
            </S.SettingsSchedulesModalContent>

            <div>
              <S.SettingsSchedulesModalPanelDivider />
              <S.SettingsSchedulesModalPanelFooterButton
                type="button"
                onClick={() =>
                  dispatch(
                    handleAddScheduleRowAction({
                      scheduleType: "normalOperatingDaysRows",
                    }),
                  )
                }
              >
                <S.SettingsSchedulesModalIconTextWrapper>
                  <SvgIcon
                    customClassName={`${BLOCK_NAME}__panel-icon`}
                    icon="Add"
                    height={18}
                  />
                  Add a new rule
                </S.SettingsSchedulesModalIconTextWrapper>
              </S.SettingsSchedulesModalPanelFooterButton>
              <S.SettingsSchedulesModalPanelDivider />
            </div>
          </S.SettingsSchedulesModalPanel>
        </S.SettingsSchedulesModalRepeatSection>
        <S.SettingsSchedulesModalExceptionSection>
          <S.SettingsSchedulesModalPanel>
            <S.SettingsSchedulesModalPanelHeader>
              <SvgIcon
                customClassName={`${BLOCK_NAME}__panel-icon`}
                icon="No"
                height={18}
              />
              <S.SettingsSchedulesModalPanelTitle>
                Except&nbsp;
                <S.SettingsSchedulesModalPanelTitleGreyed>
                  (Optional)
                </S.SettingsSchedulesModalPanelTitleGreyed>
              </S.SettingsSchedulesModalPanelTitle>
            </S.SettingsSchedulesModalPanelHeader>
            <S.SettingsSchedulesModalPanelDivider />
            <S.SettingsSchedulesModalContentExceptions>
              {specialOperatingDaysRows.map((row, index) => (
                <React.Fragment key={`specialOperating${index}`}>
                  <SettingsScheduleRow
                    rowIndex={index}
                    row={row}
                    onChange={handleUpdateSpecialOperatingDaysRows}
                    onDelete={handleDeleteSpecialOperatingDaysRows}
                    hasExceptionDatePicker
                  />
                  <S.SettingsSchedulesModalPanelDivider />
                </React.Fragment>
              ))}
            </S.SettingsSchedulesModalContentExceptions>
            <S.SettingsSchedulesModalPanelFooter>
              <S.SettingsSchedulesModalPanelFooterButton
                type="button"
                onClick={() =>
                  dispatch(
                    handleAddScheduleRowAction({
                      scheduleType: "specialOperatingDaysRows",
                    }),
                  )
                }
              >
                <S.SettingsSchedulesModalIconTextWrapper>
                  <SvgIcon
                    customClassName={`${BLOCK_NAME}__panel-icon`}
                    icon="Add"
                    height={18}
                  />
                  {specialOperatingDaysRows.length > 0
                    ? "Add a new exception"
                    : "Add an exception"}
                </S.SettingsSchedulesModalIconTextWrapper>
              </S.SettingsSchedulesModalPanelFooterButton>
              <S.SettingsSchedulesModalPanelDivider />
            </S.SettingsSchedulesModalPanelFooter>
          </S.SettingsSchedulesModalPanel>
        </S.SettingsSchedulesModalExceptionSection>
      </S.SettingsSchedulesModalContent>
      <S.SettingsSchedulesModalBottom>
        <Button
          customClassName={`${BLOCK_NAME}__button-cancel`}
          title="Cancel Schedule"
          text="Cancel"
          onClick={() => dispatch(closeModalAction())}
          light
        />
        <S.SettingsSchedulesMessageAndSave>
          {schedule.hasErrors && (
            <S.SettingsSchedulesModalErrors key="errors">
              <SvgIcon
                icon="Warning"
                customClassName="Modal__bottom__warning__icon"
                fillColor={colors.colorUIWarn}
              />
              <div>
                <S.SettingsSchedulesModalErrorsDescription>
                  {!normalOperatingDaysRows.length
                    ? "Add at least 1 rule to save this schedule"
                    : MODAL_ERROR_MESSAGE}
                </S.SettingsSchedulesModalErrorsDescription>
              </div>
            </S.SettingsSchedulesModalErrors>
          )}

          <Button
            title="Save Schedule"
            text="Save"
            icon="Cloud"
            disabled={isScheduleInvalid}
            onClick={() => dispatch(handleSaveScheduleModalAction())}
          />
        </S.SettingsSchedulesMessageAndSave>
      </S.SettingsSchedulesModalBottom>
    </div>
  );
};

SettingsSchedulesModal.isLarge = true;
