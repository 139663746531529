// @ts-strict-ignore
import Immutable from "immutable";

import { type TransferType } from "components/Shared/Pages/Responses/types";
import { type TypedMap } from "types/global";

import BaseMessageRecord, {
  type BaseMessageRecordAttributes,
} from "../BaseMessageRecord";

export type AircallDestinationType = "none" | "number" | "team" | "user";

interface VoiceHandoffMessageRecordAttributes
  extends BaseMessageRecordAttributes {
  type: "voice_handoff";
  phoneNum: string;
  dialableAddress: string;
  includeUuiHeader: boolean;
  uuiHeaders: Immutable.List<TypedMap<{ key: string; value: string }>>;
  transferType: TransferType;
  aircallDestinationType: AircallDestinationType;
  aircallDestinationId: string;
}

export const aircallDestinationTypeKey = "aircallDestinationType";
export const aircallDestinationIdKey = "aircallDestinationId";
export const dialableAddressKey = "dialableAddress";
export const phoneNumKey = "phoneNum";

export class VoiceHandoffMessageRecord extends BaseMessageRecord<VoiceHandoffMessageRecordAttributes>(
  {
    type: "voice_handoff",
    phoneNum: "",
    dialableAddress: "",
    includeUuiHeader: false,
    uuiHeaders: Immutable.List(),
    transferType: "phone_number",
    aircallDestinationType: "none",
    aircallDestinationId: "",
  },
) {
  static getInvalidFields = (
    message: VoiceHandoffMessageRecord,
  ): Immutable.List<string> => {
    const invalidFields: Set<string> = new Set();

    const phoneNumOrVarRegEx =
      /^[+]?1?[-\s.]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$|^{([\w]{24})\|(.*?)}$/i;
    const sipAddressRegex = /^.{1,}@.+$/;

    if (message.transferType === "twilio") {
      return Immutable.List(invalidFields);
    }

    if (message.transferType === "aircall") {
      if (
        message.aircallDestinationType !== "none" &&
        !message.aircallDestinationId.trim()
      ) {
        invalidFields.add(aircallDestinationIdKey);
      }

      return Immutable.List(invalidFields);
    }

    const value = message.dialableAddress.trim();

    if (
      phoneNumOrVarRegEx.test(value) &&
      message.transferType === "phone_number"
    ) {
      return Immutable.List(invalidFields);
    }

    if (sipAddressRegex.test(value) && message.transferType === "sip") {
      return Immutable.List(invalidFields);
    }

    // Backwards compatibility for customers who have a phone number saved but no dialable address.
    if (message.phoneNum) {
      return Immutable.List(invalidFields);
    }

    if (message.transferType === "amazon_connect") {
      return Immutable.List(invalidFields);
    }

    invalidFields.add(dialableAddressKey);

    return Immutable.List(invalidFields);
  };
}
